import { colors_by_name } from '../Constants';
import { useEffect } from 'react';



export const generateChartData = (dataSubset) => {
    const labels = new Set();
    dataSubset.forEach(dataset => {
        Object.keys(dataset.data).forEach(label => {
            labels.add(label);
        });
    });

    const sortedLabels = Array.from(labels).sort();

    const datasets = dataSubset.map(dataset => ({
        label: colors_by_name[dataset.name].name,
        data: sortedLabels.map(label => dataset.data[label] || 0),
        borderColor: colors_by_name[dataset.name].borderColor,
        backgroundColor: colors_by_name[dataset.name].backgroundColor, ////faker.internet.color(),
    }));

    return {
        labels: sortedLabels,
        datasets
    };
};


  export const categorizeChartData = (period, series_data) => {
    return series_data.map(dataset => {
        const filteredData = {};
        Object.entries(dataset.data).forEach(([key, value]) => {
            const fitsPeriod = (period === 'yearly' && !key.includes('_') && !key.includes('-')) ||
                (period === 'quarterly' && key.includes('T')) ||
                (period === 'semester' && key.includes('S')) ||
                (period === 'monthly' && key.includes('-'));
            if (fitsPeriod) {
                filteredData[key] = value;
            }
        });
        return {
            name: dataset.name,
            data: filteredData
        };
    });
};

export const processCountryPositions = (data) => {
    let result = {};
  
    data.forEach(item => {
      if (item.acciones !== undefined && item.acciones > 0) {
        if (result[item.isin_country]) {
          result[item.isin_country] += item.porcentaje;
        } else {
          result[item.isin_country] = item.porcentaje;
        }
      } else if (item.acciones === undefined && item.estado!='VENTA') {
        result['UNKNOWN'] = (result['UNKNOWN'] || 0) + item.porcentaje;
      }
    });
  
    // Convert object into an array of [key, value] pairs
    let entries = Object.entries(result);
  
    // Sort array based on the value
    entries.sort((a, b) => b[1] - a[1]);
  
    // Convert the sorted array back into an object
    result = Object.fromEntries(entries);
  
    return result;
  };

  export const processSectorPositions = (data) => {
    let result = {};
  
    data.forEach(item => {
      if (item.acciones !== undefined && item.acciones > 0) {
        if (result[item.isin_sector]) {
          result[item.isin_sector] += item.porcentaje;
        } else {
          result[item.isin_sector] = item.porcentaje;
        }
      } else if (item.acciones === undefined && item.estado!='VENTA') {
        result['UNKNOWN'] = (result['UNKNOWN'] || 0) + item.porcentaje;
      }
    });
  
    // Convert object into an array of [key, value] pairs
    let entries = Object.entries(result);
  
    // Sort array based on the value
    entries.sort((a, b) => b[1] - a[1]);
  
    // Convert the sorted array back into an object
    result = Object.fromEntries(entries);
  
    return result;
  };

  export const processTypePositions = (data) => {
    let result = {};
  
    data.forEach(item => {
      if (item.estado!='VENTA') {
        if (result[item.tipo_detalle]) {
          result[item.tipo_detalle] += item.porcentaje;
        } else {
          result[item.tipo_detalle] = item.porcentaje;
        }
      } 
    });
  
    // Convert object into an array of [key, value] pairs
    let entries = Object.entries(result);
  
    // Sort array based on the value
    entries.sort((a, b) => b[1] - a[1]);
  
    // Convert the sorted array back into an object
    result = Object.fromEntries(entries);
  
    return result;
  };
  
