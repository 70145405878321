import { useEffect, useState } from 'react';
import { POSITION_PERIODS_URL, POSITION_FUNDS_URL } from '../utils/Constants';
import { useFetch } from './fetchURL';
import { fetchApi } from '../utils/DataFetch';
import { useNavigate } from 'react-router-dom';


export const useFetchStock = (isinPosicion) => { // To simplify how Stock data is being fetched.
  const [quarters, setQuarters] = useState(null);
  const [USAquarters, setUSAQuarters] = useState(null);
  const [positionFundsData, setPositionFundsData] = useState(null);
  const [USApositionFundsData, setUSAPositionFundsData] = useState(null);
  const [selectedQuarter, setSelectedQuarter] = useState(null);
  const [selectedUSAQuarter, setSelectedUSAQuarter] = useState(null);
  const [fondoBaseID, setFondoBaseID] = useState(null);

  
  const { data: quarter_values } = useFetch(POSITION_PERIODS_URL(isinPosicion));
  const navigate = useNavigate();
  const isSpanishPeriod = (period) => period.includes('_S');

  const fetchFundData = (quarter, setFundData) => {
    if (!quarter) return;
    fetchApi(POSITION_FUNDS_URL(isinPosicion, quarter)).then(data => {
      setFundData(data);
      if (data.length) setFondoBaseID(data[0].fondo_base_id);
    });
  };



  useEffect(() => { // If 'quarter_values' changes, it means that we have fetched the quarters for the fund and we can set the state
    if (quarter_values && quarter_values['periodos'] && quarter_values['periodos'].length === 0) { //Posicion doesn't exist
      navigate('/404'); 
    }
    else if (quarter_values && quarter_values['periodos'] && quarter_values['periodos'].length > 0) {
      var spanish_quarters = quarter_values['periodos'].filter(quarter => isSpanishPeriod(quarter));
      var usa_quarters = quarter_values['periodos'].filter(quarter => !isSpanishPeriod(quarter));
      setQuarters(spanish_quarters);
      setSelectedQuarter(spanish_quarters[0])
      setUSAQuarters(usa_quarters);
      setSelectedUSAQuarter(usa_quarters[0])
    }
  }, [quarter_values]);

  useEffect(() => {
    fetchFundData(selectedQuarter, setPositionFundsData);
  }, [selectedQuarter]);

  useEffect(() => {
    fetchFundData(selectedUSAQuarter, setUSAPositionFundsData);
  }, [selectedUSAQuarter]);

  return { quarters, positionFundsData, fondoBaseID, selectedQuarter, setSelectedQuarter, 
    USAquarters, USApositionFundsData, selectedUSAQuarter, setSelectedUSAQuarter };
};
