import React, { useState, useEffect, useRef } from 'react';
import {
  Container,
  Button,
  Row,
  Col,
} from 'react-bootstrap';
import '../css/LandingPage.css';
import { Link } from 'react-router-dom';
//import json_funds from './files/summary_fondos.json';
import SidePanel from '../SidePanel';
import LetterSelector from './LetterSelector';

import { useFetchLetter } from '../hooks/fetchLetter';
import { USA_FLAG } from '../utils/Constants';
import SpinnerLoading from '../utils/SpinnerLoading';
//TODO: Move to other component


const USALandingPage = () => {
  const [selectedLetter, setSelectedLetter] = useState('');
  const [fundsToShow, setFundsToShow] = useState([]);
  const [country, setCountry] = useState('USA');


  const { fundList, isLoading } = useFetchLetter(country, selectedLetter);

  useEffect(() => {
    setFundsToShow(fundList)
  }, [fundList]);

  const onSelectLetter = (letter) => {
      if (letter === selectedLetter) {
        // If the same letter is clicked, deselect it and clear the fundsToShow
        setSelectedLetter(null);
        setFundsToShow([]);
      }
      else {
        setSelectedLetter(letter);
      }
    };
      


//https://chat.openai.com/share/fd1f26a1-397f-4230-9faa-586f940b1232 -> Added Sentence to Landing
  return (
    <Container className="mt-1">
    <Row className="my-1">
      <SidePanel />
    </Row>
    <Row className="my-5">
        <Col>
          <h2>All <img src={USA_FLAG} alt="US flag" style={{ width: '40px', height: '30px'}} /> US Funds and ETFs</h2>
            <LetterSelector
            selectedLetter={selectedLetter}
            onSelectLetter={onSelectLetter}
            isLoading={isLoading}
            fundsToShow={fundsToShow}
            LinkComponent={Link}
          />
        </Col>
      </Row>
      <Row className="my-5">
    <hr />
      <Col>
          <h3>Top Funds</h3>
      </Col>
    </Row>
      <Row className="my-3">
          <Col>
          <div className="text-center">
            <p><strong>Coming soon...</strong></p>
              <div className="loader-center"></div>
          </div>
          </Col>
      </Row>
      <Row className="my-5">
    <hr />
      <Col>
          <h3>Top Holdings</h3>
      </Col>
    </Row>
      <Row className="my-3">
          <Col>
          <div className="text-center">
            <p><strong>Coming soon...</strong></p>
              <div className="loader-center"></div>
          </div>
          </Col>
      </Row>
    </Container>
  );
};

export default USALandingPage;
