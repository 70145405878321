import React, { useState, useEffect, useRef } from 'react';
import {
  Container,
  Button,
  Row,
  Col,
} from 'react-bootstrap';
import '../css/LandingPage.css';
import SpinnerLoading from '../utils/SpinnerLoading';
import { Link } from 'react-router-dom';
//import json_funds from './files/summary_fondos.json';
import SidePanel from '../SidePanel';
import TableFunds from './TableFunds';
import TableStocks from './TableStocks';
import { useFetchSummary } from '../hooks/fetchSummary';
import { useFetchLetter } from '../hooks/fetchLetter';
import LetterSelector from './LetterSelector';
import { USA_FLAG, SPAIN_FLAG } from '../utils/Constants';

//TODO: Move to other component

//TODO: IMPLEMENTAR SECCIÓN ÚLTIMOS FONDOS AÑADIDOS
//TODO: LANDING SÓLO TENDRÁ UN BUSCADOR Y UN CUADRO CON LOS ÚLTIMOS FONDOS AÑADIDOS.
//TODO: AÑADIR SECCIÓN COMENTARIOS
//TODO: AÑADIR SECCIÓN FONDOS + VISITADOS


const SpanishLandingPage = () => {
  const [selectedLetter, setSelectedLetter] = useState('');
  const [fundsToShow, setFundsToShow] = useState([]);
  const [country, setCountry] = useState('SPAIN');
  const { fundList, isLoading } = useFetchLetter(country, selectedLetter)
  const [numRowsDisplayed, setNumRowsDisplayed] = useState(5);
  const [numRowsDisplayedStocks, setNumRowsDisplayedStocks] = useState(5);
  const json_funds = useRef(null);


  const {
    periodos,
    isFirstLoad,
    fundTypes,
    posicionTypes,
    fundSections,
    positionSections,
    selectedPeriodo,
    setSelectedPeriodo,
    selectedFundType,
    setSelectedFundType,
    selectedPosicionType,
    setSelectedPosicionType
    } = useFetchSummary();


  
    useEffect(() => {
      setFundsToShow(fundList)
    }, [fundList]);
  
    const onSelectLetter = (letter) => {
        if (letter === selectedLetter) {
          // If the same letter is clicked, deselect it and clear the fundsToShow
          setSelectedLetter(null);
          setFundsToShow([]);
        }
        else {
          setSelectedLetter(letter);
        }
      };
      


//https://chat.openai.com/share/fd1f26a1-397f-4230-9faa-586f940b1232 -> Added Sentence to Landing
  return (
    <Container className="mt-1">
    <Row className="my-1">
      <SidePanel />
    </Row>
    <Row className="my-5">
        <Col>
          <h2>All <img src={SPAIN_FLAG} alt="ES flag" style={{ width: '40px', height: '30px'}} /> Spanish Funds</h2>

          <LetterSelector
            selectedLetter={selectedLetter}
            onSelectLetter={onSelectLetter}
            isLoading={isLoading}
            fundsToShow={fundsToShow}
            LinkComponent={Link}
          />
        </Col>
      </Row>
      {isFirstLoad  ? (
        <SpinnerLoading/>
      ) : (
        <>
        <TableFunds {...{fundSections, periodos, fundTypes, selectedPeriodo, setSelectedPeriodo, selectedFundType, setSelectedFundType, numRowsDisplayed, setNumRowsDisplayed}}/>
        <TableStocks {...{positionSections, periodos, posicionTypes, selectedPeriodo, setSelectedPeriodo, selectedPosicionType, setSelectedPosicionType, numRowsDisplayedStocks, setNumRowsDisplayedStocks}}/>
        <br />
      </>
        )}
    </Container>
  );
};

export default SpanishLandingPage;
