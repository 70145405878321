import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { ListGroup } from 'react-bootstrap';
import { getFlagUrl } from '../utils/DataHelpers'

const OptionList = ({ filteredData }) => {

  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (event, newPath) => {      
    setSearch('');
  
    // Compare current path with new path
    if (location.pathname === newPath) {
      event.preventDefault();
      // They are the same, don't navigate
      return;
    }
    // They are different, clear search and navigate
    navigate(newPath);
  };



  return (
    <ListGroup> 
      {filteredData.map((item, index) => {
        // Determine if we should show a flag
        const showFlag = item.internal_type === 'Fund' && item.country;
        const flagUrl = showFlag ? getFlagUrl(item.country) : '';

        // Determine the element to wrap the ListGroup.Item
        const internalLink = item.internal_type === 'Fund';

        const Wrapper = internalLink ? Link : 'a'; // Use Link for internal navigation
        const wrapperProps = internalLink ? 
          { to: `/fund_by_name/${item.internal_id}`, onClick: (event) => handleClick(event, `/fund_by_name/${item.internal_id}`)}          : 
          { href: `/position/${item.internal_id}` }; 
  

        return (
          <Wrapper key={index} {...wrapperProps}>
            <ListGroup.Item style={item.internal_type==='Fund' ? {backgroundColor: '#f2f2f2'} : null}>
              {showFlag && flagUrl && <img src={flagUrl} alt={`${item.country} flag`} style={{ width: '20px', height: '15px', marginRight: '5px' }} />}
              <strong>{item.name}</strong> {item.ticker !== null ? `- ${item.ticker}` : null}
              <br />
              {item.isin}
              <br />
              <span style={{ fontWeight: 'bold', fontSize: '0.8rem', color: 'gray' }}>
                {item.internal_type.toUpperCase()} - {item.tipo}
              </span>
            </ListGroup.Item>
          </Wrapper>
        );
      })}
    </ListGroup>
  );
};

export default OptionList;
