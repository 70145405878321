import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import FundDetails from './FundDetails';
import StockDetails from './StockDetails';
import SpanishLandingPage from './landing/SpanishLandingPage';
import USALandingPage from './landing/USALandingPage';
import LandingPage from './LandingPage';
import Footer from './Footer';
import SearchBar from './search_bar/SearchBar';
import NotFoundPage from './utils/NotFoundPage';
import GoogleAnalyticsReporter from './other/GoogleAnalyticsReporter';
 //TO-DO: AÑADIR TIPO DE POSICIÓN CUANDO EL USUARIO BUSQUE POR POSICIÓN


// Import other required components and hooks

const App = () => {

  return (
    <Router>
      <div className="d-flex flex-column min-vh-100">
       <SearchBar />
       <GoogleAnalyticsReporter />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/fund/:fundId" element={<FundDetails />} />
          <Route path="/fund_by_name/:fundNameId" element={<FundDetails />} />
          <Route path="/fund_by_isin/:fundIsin" element={<FundDetails />} />
          <Route path="/position/:isinPosicion" element={<StockDetails />} />
          <Route path="/position_search/:isinPosicion" element={<StockDetails />} />
          <Route path="/spain_funds" element={<SpanishLandingPage />} />
          <Route path="/usa_funds" element={<USALandingPage />} />
          <Route  path="*" element={<NotFoundPage/>}/>
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

// Footer component

export default App;
