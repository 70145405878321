import {  nonEurKeys, porcentajeKeys} from '../utils/Constants';
import { colors_by_name } from './Constants';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { USA_FLAG, SPAIN_FLAG } from '../utils/Constants';

const labelMappings = {
  comision_gestion_patrimonio: 'C.Gestión',
  comision_depositario: 'C.Depositario',
  comision_gestion_resultados: 'C.Resultados',
  isin_fondo: 'ISIN', 
  moneda: 'Currency',
  tipo_fondo: 'Fund Type',
  fecha_registro: 'Inception',
  vocacion_inversora: 'Strategy',
  nombre_entidad: 'Firm',
  nombre_clase: 'Class Name',
  tipo_producto: 'Type',
  distribuye_dividendos: 'Distributing',
  rentabilidad: 'Return',
  patrimonio: 'Wealth',
  total_portfolio: 'Total Portfolio',
  liquidez_portfolio: 'Liquidity',
  porcentaje_invertido: 'Invested %',
  comision_gestion_patrimonio: 'Management Fee',
  comision_gestion_resultados: 'Results Fee',
  rotacion_cartera: 'Portfolio Turnover',
  volatilidad: 'Volatility',
  comision_depositario: 'Custodian Fee',
  comision_gestion_resultados : 'Results Fee',
  performance_since_inception: 'Performance Since Inception',
  morningstar_rating: 'Morningstar Rating',
  sustainability_rating: 'Sustainability Rating',
  num_participes: 'Nº of Shareholders',
  num_participaciones: 'Nº of Shares'

  // Add more mappings as needed
};


export const formatLabel = (label) => {
  if (labelMappings[label]) {
    return labelMappings[label];
  }
    const capitalize = (word) => word.charAt(0).toUpperCase() + word.slice(1);  
    return label
      .split('_')
      .map(capitalize)
      .join(' ');
  };

  export const format_data = (data, processKeys) => {
    const newData = {};
    
    Object.entries(data).forEach(([key, value]) => {
      if (!processKeys.includes(key) || typeof value !== 'string') {
        newData[key] = value;
      } else {
        try {
          const jsonString = value.replace(/'/g, '"');
          newData[key] = JSON.parse(jsonString);
        } catch (error) {
          console.error('Error parsing input string:', error);
          newData[key] = value;
        }
      }
    });
  
    return newData;
  };


export const parseCommentary = (text) => {
    const titleRegex = /^(\d+\s*\.)\s*([^.]+)\./;
    const subtitleRegex = /^(\s*[a-z][\.|\)])\s*([^.]+)\.*/;
  
    const lines = text.split(/\r?\n/);
  
    return lines.map((line, index) => {
      const isTitle = titleRegex.test(line);
      const isSubtitle = subtitleRegex.test(line);
  
      if (isTitle || isSubtitle) {
        return (
          <p key={index}>
            <strong>{line}</strong>
          </p>
        );
      } else {
        return <p key={index}>{line}</p>;
      }
    });
  };


export const CAGR_calculation = (inception_date, per_since_inception) => 
  {
    const inceptionDate = new Date(inception_date);
    const endDate = new Date(); // Current date
    const performanceSinceInception = per_since_inception;
    
    const multiplier = 1 + (performanceSinceInception / 100);
    
    const timeDiff = endDate - inceptionDate;
    const years = timeDiff / (365.25 * 24 * 60 * 60 * 1000); // milliseconds to years, accounting for leap years
    
    const CAGR = (Math.pow(multiplier, 1 / years) - 1) * 100; // CAGR in percentage
    console.log(`The annualized return (CAGR) is ${CAGR.toFixed(2)}%`);
    return CAGR.toFixed(2);
  }


  export const getFlagUrl = (country) => {
    switch (country) {
      case 'SPAIN': return SPAIN_FLAG;
      case 'USA': return USA_FLAG;
      default: return SPAIN_FLAG;
    }
  };
      
  
export const isNumeric = (value) => {
    return !isNaN(parseFloat(value)) && isFinite(value);
};


export const prettyPrintNumber = (number, locale) => {
    return number.toLocaleString(locale);
};


export const formatValue = (value, label, moneda = null) => {
    if (label === 'tipo_producto') {
      switch (value) {
        case "1":
          return "Fondo que replica o reproduce un índice";
        case "2":
          return "Fondo que toma como referencia un índice";
        case "4":
          return "Fondo subordinado de IIC extranjera";
        case "5":
          return "Fondo constituido bajo convenios con el Estado y CCAA o cualquier otra institución";
        case "6":
          return "Sociedad que invierte más del 10% en otros fondos y/o sociedades";
        case "7":
          return "Sociedad que invierte mayoritariamente en otros fondos y/o sociedades";
        case "8":
          return "Otros";
        default:
          return value;
      }
    }
    
    if (label === 'vocacion_inversora') {
      switch (value) {
        case "2":
          return "Renta Fija Euro";
        case "3":
          return "Renta Fija Internacional";
        case "4":
          return "Renta Fija Mixto Euro";
        case "5":
          return "Renta Fija Mixto Internacional";
        case "6":
          return "Renta Variable Mixta Euro";
        case "7":
          return "Renta Variable Mixta Internacional";
        case "8":
          return "Renta Variable Euro";
        case "9":
          return "Renta Variable Internacional";
        case "10":
          return "IIC que Replica un Índice";
        case "11":
          return "Garantizado de Rendimiento Fijo";
        case "12":
          return "Garantizado de Rendimiento Variable";
        case "13":
          return "De Garantía Parcial";
        case "14":
          return "Retorno Absoluto";
        case "15":
          return "Global";
        case "19":
          return "FMM Estándar de Valor Liquidativo Variable";
        case "20":
          return "Renta Fija Euro Corto Plazo";
        case "21":
          return "IIC que Replica un Índice";
        case "22":
          return "IIC con Objetivo Concreto de Rentabilidad No Garantizado";
        default:
          return value;
      }
    }

if (isNumeric(value)) {
  if (porcentajeKeys.includes(label)) {
    return `${prettyPrintNumber(parseFloat(value), 'es-ES')}%`;
  }
  if (nonEurKeys.includes(label)) {
    return prettyPrintNumber(parseFloat(value), 'es-ES');
  }
  return `${prettyPrintNumber(parseFloat(value), 'es-ES')}${" "+moneda || '€'}`;
}
return value;
};



export const formatPercentage = (percentage) => {
  if (percentage === null || percentage === undefined) {
    return '';
  }
  if (percentage >0)
    return `+${percentage}%`;
  if (percentage < 0)
    return `${percentage}%`;
  if (percentage === 0)
    return `~${percentage}%`;
  else 
    return '';
};


export const formatTotalWithCurrency = (total, moneda) => `${total.toLocaleString()} ${moneda || ''}`;

export const sortDataByPercentage = (data) => {
  return data.slice().sort((a, b) => b.porcentaje - a.porcentaje);
};

export const style = (percentage) => ({
  color: percentage > 0 ? 'green' : percentage <0 ? 'red' : 'gray'
});

export const apply_percentage_filter = (percentage) => {
  if (percentage === null || percentage === undefined) {
    return '';
  }
  if (Math.abs(percentage)>=2)
    return percentage;
  else 
    return 0;
}

export const isValidEmail = email => /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/.test(email);


export const processPercentageRow = (item) => {
  if (item.estado === 'VENTA') {
    return (
      <>
        0%
        <AiOutlineInfoCircle 
          className="info-icon" 
          title={`Porcentaje anterior: ${item.porcentaje}%`} 
        />
      </>
    );
  } else {
    return `${item.porcentaje}%`;
  }
}

export const processTotalRow = (item) => {
  if (item.estado === 'VENTA') {
    return (
      <>
        {formatTotalWithCurrency(0, (item.moneda==="United States Dollar") ? 'USD' : item.moneda)}
        <AiOutlineInfoCircle 
          className="info-icon" 
          title={`Total anterior: ${formatTotalWithCurrency(item.total, item.moneda)}`} 
        />
      </>
    );
  } else {
    return formatTotalWithCurrency(item.total, item.moneda);
  }
}


