import React, { useEffect, useRef } from 'react';

let tvScriptLoadingPromise;

export default function TradingViewSymbolProfileWidget({ticker}) {
  const widgetRef = useRef();
  const onLoadScriptRef = useRef();

  useEffect(() => {
    onLoadScriptRef.current = createWidget;

    if (!tvScriptLoadingPromise) {
      tvScriptLoadingPromise = new Promise((resolve) => {
        const script = document.createElement('script');
        script.id = 'tradingview-widget-loading-script';
        script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-symbol-profile.js';
        script.type = 'text/javascript';
        script.async = true;
        script.onload = resolve;
        script.innerHTML = JSON.stringify({
          "width": "100%",
          "height": 480,
          "colorTheme": "light",
          "isTransparent": false,
          "symbol": ticker, //"NASDAQ:AAPL"
          "locale": "en"
        });

        widgetRef.current.appendChild(script);
      });
    }
 

    return () => onLoadScriptRef.current = null;

    function createWidget() {
      // Check if the widget was already created
      if (document.getElementById('tradingview-widget-loading-script')) {
        return;
      }

      // Create the widget
      new window.TradingView.widget({
        container_id: 'tradingview-widget-container__widget',
        width: '100%',
        height: 480,
        colorTheme: 'light',
        isTransparent: false,
        symbol: ticker, //'NASDAQ:AAPL'
        locale: 'es',
      });
    }
  }, []);

  return (
    <div className="tradingview-widget-container">
      <div ref={widgetRef} className="tradingview-widget-container__widget"></div>
    </div>
  );
}
