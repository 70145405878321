import { Card, Form, Button, Row, Col, Alert } from 'react-bootstrap';
import React, { useState } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { ListGroup } from 'react-bootstrap';
import { pushApi } from '../utils/DataFetch';
import { isValidEmail } from '../utils/DataHelpers';
import { USER_FEEDBACK_URL } from '../utils/Constants';
import { useSetLanguage } from '../hooks/setLanguage';



export const LandingPageInfo = () => {
    const [email, setEmail] = useState('');
    const [user_comment, setUserComment] = useState('');
    const [error, setError] = useState('');
    const [isValid, setValid] = useState(false);
    const [isFirstLoad, setFirstLoad] = useState(true);

    const language = useSetLanguage()

    const textContent = {
        en: {
            header1: "Added Features",
            header2: "Your Feedback is Important",
            emailLabel: "Enter your email:",
            feedbackLabel: "Enter your feedback, suggestion, or request:",
            submitButton: "Send",
            successMessage: "Feedback successfully sent!",
            errorMessageEmail: "Please enter a valid email address.",
            errorMessageComment: "Please enter a comment of at least 10 characters.",
            featureDescriptions: [
                {text: "US funds and ETFS", tooltip: "Funds and ETFs of the United States will be updated on a weekly basis (most of them report that information each quarter)."},
                {text: "Information on partial buys/sells", tooltip: "Within a fund or position, in the list of positions or the list of funds, it is possible to see what percentage of shares were bought/sold in that period compared to the previous period."},
                {text: "More information on stocks, as well as their price changes between periods", tooltip: "Within a fund, in the list of positions, the percentage change in price for the stock/position compared to the previous period can be seen."},
                {text: "More charts representing the evolution of each fund", tooltip: "Within a fund, in the 'Evolution' section, charts summarizing the evolution of the fund for various factors: fees, returns, participants, assets can be seen."},
                {text: "Charts to easily visualize the fund holdings", tooltip: "Within a fund, in the list of positions a 'Charts' subsection has been added, where the positions of the fund by countries, sectors, or type of position can be seen."},
                {text: "Improved search bar", tooltip: "The search bar now search over all holdings, funds/ETFs by thier name, ISIN and/or ticker."}
            ]
        },
        es: {
            header1: "Funcionalidades añadidas",
            header2: "Tu opinión es importante",
            emailLabel: "Introduce tu mail:",
            feedbackLabel: "Introduce tu feedback, sugerencia o petición:",
            submitButton: "Enviar",
            successMessage: "¡Feedback enviado con éxito!",
            errorMessageEmail: "Por favor, introduce un correo electrónico válido.",
            errorMessageComment: "Por favor, introduce un comentario de al menos 10 caracteres.",
            featureDescriptions: [
                {text: "Fondos y ETFs de los Estados Unidos", tooltip: "Semanalmente se actualizarán los fondos y ETFs de los Estados Unidos (la mayoría reportan esta información cada cuatrimestre)."},
                {text: "Información de las compras/ventas parciales", tooltip: "Dentro de un fondo o posición, en la lista de posiciones o la lista de fondos puede verse qué porcentaje de acciones han comprado/vendido en ese período con respecto al período anterior."},
                {text: "Más información de las acciones, así como de su precio entre períodos", tooltip: "Dentro de un fondo, en la lista de posiciones puede verse el porcentaje de cambio de precio para la acción/posición con respecto al período anterior."},
                {text: "Más gráficos que representen la evolución del fondo", tooltip: "Dentro de un fondo, en la sección 'Evolución', pueden verse los gráficos que resumen la evolución del fondo para varios factores: comisiones, rentabilidad, partícipes, patrimonio..."},
                {text: "Gráficos para visualizar, fácilmente, las posiciones del fondo", tooltip: "Dentro de un fondo, en la lista de posiciones ha sido añadida una subsección 'Gráficos', en los que se pueden ver representadas las posiciones del fondo por países, sectores o por tipo de posición."},
                {text: "Buscador mejorado", tooltip: "El buscador ahora busca en todas las posiciones y fondos por su nombre, ISIN y/o ticker."}

            ]
        }
    };

    const handleSubmit = e => {
        e.preventDefault();
        setFirstLoad(false);
        if (!isValidEmail(email)) {
            setError(textContent[language].errorMessageEmail);
            setValid(false);
        } else if (user_comment.length <= 9) {
            setError(textContent[language].errorMessageComment);
            setValid(false);
        } else {
            setValid(true);
            const data = { email, feature_comment: user_comment, feature_vote: 'landing' }
            pushApi(data, USER_FEEDBACK_URL);
            setEmail('');
            setUserComment('');
        }          
    };

    return (
        <Row className="my-3 justify-content-center">
            <Col xs={12} md={6}>
            <Card className="h-100">
            <Card.Header className="bg-light text-dark border-bottom-0 font-weight-bold h4">{textContent[language].header1}</Card.Header>
            <ListGroup variant="flush">
                {textContent[language].featureDescriptions.map((item, index) => (
                    <ListGroup.Item key={index} title={item.tooltip}>
                        {item.text}<AiOutlineInfoCircle className="info-icon" />
                    </ListGroup.Item>
                ))}
            </ListGroup>
            </Card>
            </Col>
            <Col xs={12} md={6}>
            <Card className="h-100">
            <Card.Header className="bg-light text-dark border-bottom-0 font-weight-bold h4">{textContent[language].header2}</Card.Header>
            <Card.Body>
                <Form onSubmit={handleSubmit} noValidate>
                <Form.Group className="mb-3" controlId="userEmail">
                    <Form.Label>{textContent[language].emailLabel}</Form.Label>
                    <Form.Control type="email" placeholder={textContent[language].emailLabel} value={email} onChange={(e) => setEmail(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="userFeedback">
                    <Form.Label>{textContent[language].feedbackLabel}</Form.Label>
                    <Form.Control as="textarea" rows={3} placeholder="Enter here..." value={user_comment} onChange={(e) => setUserComment(e.target.value)} />
                </Form.Group>
                {isValid && (
                    <Alert variant="success">
                        {textContent[language].successMessage}
                    </Alert>
                )}
                {!isValid && !isFirstLoad && <Alert variant="danger">{error}</Alert>}
                <Button variant="primary" type="submit" className="w-100">{textContent[language].submitButton}</Button>
                </Form>
            </Card.Body>
            </Card>
            </Col>
        </Row>
    );
};
