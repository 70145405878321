import pako from 'pako';


export const fetchApi = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      throw error;
    }
  };

  export const fetchJSON = async (url) => {
    try {
      const response = await fetch(url);
      const compressedData = await response.arrayBuffer();
      const decompressedData = pako.inflate(new Uint8Array(compressedData), { to: 'string' });
      const jsonData = JSON.parse(decompressedData);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return jsonData;
    } catch (error) {
      throw error;
    }
  };


  export const pushApi = (data, url) => {
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    }).then((data) => {
      console.log('Success:', data);
    }).catch((error) => {
      console.error('Error:', error);
    });
  };