import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate, useLocation } from 'react-router-dom';
import { FaSearch, FaTwitter, FaEnvelope } from 'react-icons/fa';
import {
  Container,
  Navbar,
  Form,
  ListGroup,
  Dropdown,
  InputGroup,
  Card
} from 'react-bootstrap';
//import json_funds from './files/summary_fondos.json';
//import json_posiciones from './files/summary_posiciones.json';
import { useFetchOptions } from '../hooks/fetchOptions';
import OptionList from './OptionList';
import { USA_FLAG, SPAIN_FLAG } from '../utils/Constants';

const SearchBar = () => {
  // Other component state and functions

    const [search, setSearch] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const searchBarRef = useRef(null);

    //const allDataPosiciones = Object.values(json_funds['fondos']).flat();

    const {
      optionsList,
      isLoading
    } = useFetchOptions(search)

    useEffect(() => {
      setFilteredData(optionsList)
    }, [optionsList]);

    useEffect(() => {
      document.addEventListener('click', handleClickOutsideSearchBar);
    
      // Clean up the event listener when the component is unmounted
      return () => {
        document.removeEventListener('click', handleClickOutsideSearchBar);
      };
    }, []);

    const handleClickOutsideSearchBar = (event) => {
      if (searchBarRef.current && event.target !== searchBarRef.current) {
        setSearch('');
      }
    };
    

  return (
        <Navbar bg="primary" variant="dark" >
          <Container>
          <Navbar.Brand href="/" style={{ marginLeft: '0px' }}>
            <img
              alt=""
              src="https://cnmv-public-files.s3.eu-west-3.amazonaws.com/images/INFONDOS_confondo-azul.png" // Here's where you put your image path
              style={{
                maxWidth: '240px',
                maxHeight: '40px'
              }}
              className="d-inline-block align-top"
            />
            </Navbar.Brand>
            <div className="d-flex align-items-center flex-grow-1" >
        <InputGroup className="search-form">
            <InputGroup.Text><FaSearch/></InputGroup.Text>
            <Form.Control
                ref={searchBarRef}
                type="search"
                placeholder="Search for Funds or Stocks by Name, ISIN or Ticker"
                className="search-input"
                aria-label="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
        </InputGroup>
        {search.length > 2 && (
        <div className="search-results-container">
          <OptionList filteredData={filteredData}/>
        </div>
      )}
        <div className="flags-container">
          {/* Container for flag-icons right after search bar */}
          <Navbar.Brand className="flag-icons">
            <Link to="/usa_funds">
              <img src={USA_FLAG} alt="USA flag" style={{ width: '20px', height: '15px' }} />
              {" Funds"}
            </Link>
          </Navbar.Brand>
          <Navbar.Brand className="flag-icons">
            <Link to="/spain_funds">
              <img src={SPAIN_FLAG} alt="ES flag" style={{ width: '20px', height: '15px'}} />
              {" Funds"}
            </Link>
          </Navbar.Brand>
        </div>
      </div>
          </Container>
            <Navbar.Brand className="social-icons" href="https://x.com/infondos" style={{ position: 'absolute', right: '100px' }}>
              <FaTwitter size={30} color="#FFF" />
            </Navbar.Brand>
            <Navbar.Brand className="social-icons" href="mailto:info@infondos.com" style={{ position: 'absolute', right: '50px' }}>
              <FaEnvelope size={30} color="#FFF" />
            </Navbar.Brand>
        </Navbar>
  );
};

// Footer component

export default SearchBar;
