import React, { useState, useEffect, useReducer } from 'react';
import { Offcanvas, Button, Form, FormGroup, FormLabel, FormControl, FormCheck, Card, Alert } from 'react-bootstrap';
import { FiChevronsRight, FiChevronsLeft } from 'react-icons/fi';
import { pushApi } from './utils/DataFetch';
import { USER_FEEDBACK_URL } from './utils/Constants';
import { isValidEmail } from './utils/DataHelpers';
import { useSetLanguage } from './hooks/setLanguage';


const initialState = { feature_vote: '', feature_comment: '' };

//TODO: Permitir enviar feedback sin especificar el mail? 

function reducer(state, action) {
  switch (action.type) {
    case 'SET_FEATURE':
      return { feature_vote: action.payload.id, feature_comment: action.payload.comment };
    case 'RESET':
      return initialState;
    default:
      return state;
  }
}

const SidePanel = () => {
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState('');
  const [feature, dispatch] = useReducer(reducer, initialState);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 600);
  const [emailError, setEmailError] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isFeedbackSent, setIsFeedbackSent] = useState(false);
  const language = useSetLanguage()


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);



  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 600);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleEmailSubmit = e => {
    e.preventDefault();
    if (isValidEmail(email)) {
      setIsEmailValid(true);
      pushApi({ email }, USER_FEEDBACK_URL);
      setEmail('');
      setEmailError('');
    } else {
      setIsEmailValid(false);
      setEmailError(textContent[language].validEmailError);
    }
  };
  

  const handleFeatureSubmit = e => {
    setIsFeedbackSent(true);
    e.preventDefault();
    pushApi(feature, USER_FEEDBACK_URL);
    dispatch({ type: 'RESET' });
  };

  const handleFeatureChange = (e) => {
    setIsFeedbackSent(false);
    dispatch({
      type: 'SET_FEATURE',
      payload: {
        id: e.target.id,
        comment: e.target.nextSibling.textContent
      }
    });
  };

  const textContent = {
    en: {
      headerTitle: "Don't miss a thing!",
      emailPrompt: "Leave your email to get the latest updates and features in advance.",
      helpUs: "Help us improve!",
      whatFeature: "Which feature are you most interested in?",
      enterYourOwn: "Or enter your own option:",
      enterHere: "Enter here...",
      emailSuccess: "Email sent successfully!",
      feedbackSuccess: "Feedback sent successfully!",
      sendButton: "Send",
      typeMail: "Type your mail",
      validEmailError: "Please enter a valid email address.",
      feature1: "Include derivative information",
      feature2:"Access more historical data (past periods)",
      feature3: "Ability to export data",
      feature4: "More information on stocks",
      feature5: "Attach official SEC-CNMV documents for each fund",
      feature6: "More interactivity in tables",
      feature7: "Compare portfolios",
      feature8: "Comments section (by fund, period, and position)",
      feature9: "Login with notifications + create favorite funds lists",
      feature10: "International funds and ETFs",
      feature11: "More real-time data (daily net asset value of funds)",
      feature12: "Allow managers to update information on their funds",
      feature13: "ChatBot with all fund information"
    },
    es: {
      headerTitle: "¡No te pierdas nada!",
      emailPrompt: "Deja tu mail para acceder a las últimas actualizaciones y funcionalidades en primicia.",
      helpUs: "Ayúdanos a mejorar!",
      whatFeature: "¿En qué característica estás más interesado?",
      enterYourOwn: "O introduce tu propia opción:",
      enterHere: "Introduce aquí...",
      emailSuccess: "¡Correo enviado con éxito!",
      feedbackSuccess: "¡Feedback enviado con éxito!",
      sendButton: "Enviar",
      typeMail: "Introduce tu mail",
      validEmailError: "Por favor, introduce un correo electrónico válido.",
      feature1: "Incluir la información de los derivados",
      feature2: "Consultar más datos históricos (más períodos pasados)",
      feature3: "Poder exportar los datos",
      feature4: "Más información sobre las acciones",
      feature5: "Adjuntar los documentos oficiales de la SEC o CNMV para cada fondo",
      feature6: "Más interactividad en las tablas",
      feature7: "Comparar portfolios",
      feature8: "Sección de comentarios (por fondo, período y posición)",
      feature9: "Login con avisos + crear listas con fondos favoritos",
      feature10: "Fondos y ETFs internacionales",
      feature11: "Más datos en tiempo real (valor liquidativo diario de los fondos)",
      feature12: "Permitir que los gestores actualicen la información de sus fondos",
      feature13: "ChatBot con toda la información de los fondos"
    }
  };

  const renderFeatureForm = () => {
    return (
      <>
      <Form onSubmit={handleFeatureSubmit}>
      <FormLabel>{textContent[language].whatFeature}</FormLabel>
      <FormCheck type="radio" label={textContent[language].feature1} name="featurePoll" id="feature1" onChange={handleFeatureChange} />
      <FormCheck type="radio" label={textContent[language].feature2} name="featurePoll" id="feature2" onChange={handleFeatureChange} />
      <FormCheck type="radio" label={textContent[language].feature3} name="featurePoll" id="feature3" onChange={handleFeatureChange} />
      <FormCheck type="radio" label={textContent[language].feature4} name="featurePoll" id="feature4" onChange={handleFeatureChange} />
      <FormCheck type="radio" label={textContent[language].feature5} name="featurePoll" id="feature5" onChange={handleFeatureChange} />
      <FormCheck type="radio" label={textContent[language].feature6} name="featurePoll" id="feature6" onChange={handleFeatureChange} />
      <FormCheck type="radio" label={textContent[language].feature7} name="featurePoll" id="feature7" onChange={handleFeatureChange} />
      <FormCheck type="radio" label={textContent[language].feature8} name="featurePoll" id="feature8" onChange={handleFeatureChange} />
      <FormCheck type="radio" label={textContent[language].feature9} name="featurePoll" id="feature9" onChange={handleFeatureChange} />
      <FormCheck type="radio" label={textContent[language].feature10} name="featurePoll" id="feature10" onChange={handleFeatureChange} />
      <FormCheck type="radio" label={textContent[language].feature11} name="featurePoll" id="feature11" onChange={handleFeatureChange} />
      <FormCheck type="radio" label={textContent[language].feature12} name="featurePoll" id="feature12" onChange={handleFeatureChange} />
      <FormCheck type="radio" label={textContent[language].feature13} name="featurePoll" id="feature13" onChange={handleFeatureChange} />

      <hr />
      <FormGroup className="mb-3">
        <FormLabel>{textContent[language].enterYourOwn}</FormLabel>
        <FormControl type="text" placeholder={textContent[language].enterHere} onChange={(e) => dispatch({type:'SET_FEATURE', payload:{id:'featureX', comment:e.target.value}})}/>
      </FormGroup>
      <div className="text-center">
      {isFeedbackSent && (
          <Alert variant="success">
            {textContent[language].feedbackSuccess}
          </Alert>
      )}
      <Button variant="primary" type="submit" className="mt-3">
      {textContent[language].sendButton}
      </Button>
      </div>
    </Form>
    </>
    )
  }

  const renderEmailForm = () => {
    return (
      <>
        {isEmailValid && (
          <Alert variant="success">
            {textContent[language].emailSuccess}
          </Alert>
        )}
        <Form onSubmit={handleEmailSubmit} noValidate>
          <FormGroup className="mb-3">
            <FormControl type="email" placeholder={textContent[language].typeMail} onChange={(e) => setEmail(e.target.value)} />
            {emailError && <Alert variant="danger">{emailError}</Alert>}
          </FormGroup>
          <div className="text-center">
            <Button variant="primary" type="submit"> 
            {textContent[language].sendButton}
            </Button>
          </div>
        </Form>
      </>
    );
  }
  

  return (
    <>
    <Button 
      variant="primary" 
      onClick={show ? handleClose : handleShow} 
      className="side-panel-button"
      style={{width: '50px', right: show ? (isSmallScreen ? '350px' : '400px') : '0'}}
    >
      {show ? <FiChevronsRight size={32} /> : <FiChevronsLeft size={32} />}
    </Button>

      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{textContent[language].headerTitle}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="mb-4">
            <Card.Body>
              <h5> {textContent[language].emailPrompt}</h5>
              {renderEmailForm()}
            </Card.Body>
          </Card>
          <hr />
          <Card>
            <Card.Body>
              <h5>{textContent[language].helpUs}</h5>
                {renderFeatureForm()}
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>

      <style jsx>{`
        .side-panel-button {
          position: fixed;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          z-index: 1301;
          width: 50px;
          height: 50px;
          transition: right 0.3s ease-in-out;
        }
      `}</style>
    </>
  );
};

export default SidePanel;
