import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';


//TODO: Add something below the message that the page has not been loaded. Maybe a pool of famous investing sentences?

function NotFoundPage() {
    const location = useLocation();
    const errorMessage = location.state?.errorMessage || 'The page you\'re looking for doesn\'t exist.';

  return (
    <Container fluid className="p-3 bg-light text-center">
      <Row>
        <Col>
          <h1>404 Not Found</h1>
          <p>
            {errorMessage}
          </p>
          <Button variant="primary" href="/">Go Home</Button>
        </Col>
      </Row>
    </Container>
  );
}

export default NotFoundPage;
