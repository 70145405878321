import React from 'react';
import {
  Button,
  Row,
  Col,
  Tabs, Tab, Table
} from 'react-bootstrap';
import '../css/LandingPage.css';
import CustomDropdown from '../utils/CustomDropdown';
import { Link } from 'react-router-dom';
import { prettyPrintNumber } from '../utils/DataHelpers';
import { TableButton, DropDownDefault } from './TableFunds';

const DropdownSection = ({ periodos, posicionTypes, selectedPeriodo, setSelectedPeriodo, selectedPosicionType, setSelectedPosicionType }) => {
  return (
  <Row className="my-5">
    <hr />
    <Col md={4} xs={6}>
      <h3>Top Holdings</h3>
    </Col>
    <DropDownDefault periodos={periodos} selectedPeriodo={selectedPeriodo} setSelectedPeriodo={setSelectedPeriodo} 
    types={posicionTypes} selectedType={selectedPosicionType} setSelectedType={setSelectedPosicionType}/>
  </Row>
  )
}


const TableSection = ({positionSections, numRowsDisplayedStocks, setNumRowsDisplayedStocks}) => {
  return (
    <Row>
    <Col>
    {positionSections && positionSections.length > 0 && (

      <Tabs defaultActiveKey={positionSections[0].title} className="mb-3">
        {positionSections.slice(0, 3).map((section, index) => (
          <Tab key={index} eventKey={section.title} title={section.title}>
            <Table striped bordered hover responsive size="sm">
              <thead>
                <tr>
                  <th>Holding Name</th>
                  <th>ISIN</th>
                  <th>Ticker</th>
                  <th>Nº of funds</th>
                  <th>Average %</th>
                  <th>Average Value</th>
                </tr>
              </thead>
              <tbody>
                {section.funds 
                  ? section.funds.slice(0, numRowsDisplayedStocks).map((fund, idx) => (
                  <tr key={idx}>
                    <td>
                      <Link to={`/position/${fund.isin_posicion}`}>{fund.name}</Link>
                    </td>
                    <td>
                      <Link to={`/position/${fund.isin_posicion}`}>{fund.isin_posicion}</Link>
                    </td>
                    <td>
                      <Link to={`/position/${fund.isin_posicion}`}>{fund.ticker}</Link>
                    </td>
                    <td>{prettyPrintNumber(parseFloat(fund.count_posiciones), 'es-ES')}</td>
                    <td>{prettyPrintNumber(parseFloat(fund.porcentaje_media), 'es-ES')}%</td>
                    <td>{prettyPrintNumber(parseFloat(fund.total_media), 'es-ES')}€</td>

                  </tr>
                ))
                : <tr></tr>
              }
              </tbody>
          </Table>
          <div className="text-center">
          <TableButton  funds={section.funds} numRowsDisplayed={numRowsDisplayedStocks} setNumRowsDisplayed={setNumRowsDisplayedStocks} />

        </div>  
          </Tab>
        ))}
        {positionSections.slice(3).map((section, index) => (
          <Tab key={index} eventKey={section.title} title={section.title}>
            <Table striped bordered hover responsive size="sm">
              <thead>
                <tr>
                  <th>Holding Name</th>
                  <th>ISIN</th>
                  <th>Ticker</th>
                  <th>Fund Name</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                {section.funds.slice(0, numRowsDisplayedStocks).map((fund, idx) => (
                  <tr key={idx}>
                    <td>
                      <Link to={`/position/${fund.isin_posicion}`}>{fund.name}</Link>
                    </td>
                    <td>
                      <Link to={`/position/${fund.isin_posicion}`}>{fund.isin_posicion}</Link>
                    </td>
                    <td>
                      <Link to={`/position/${fund.isin_posicion}`}>{fund.ticker}</Link>
                    </td>
                    <td>
                      <Link to={`/fund/${fund.fondo_base_id}`}>{fund.fondo_nombre}</Link>
                    </td>
                    <td>{prettyPrintNumber(parseFloat(fund.value), 'es-ES')}{(section.title == "% en el Fondo") ? "%" : "€"}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <TableButton  funds={section.funds} numRowsDisplayed={numRowsDisplayedStocks} setNumRowsDisplayed={setNumRowsDisplayedStocks} />
          </Tab>
        ))}
      </Tabs>
    )}
    </Col>
  </Row>
  )
}


const TableStocks = ({ positionSections, periodos, posicionTypes, selectedPeriodo, setSelectedPeriodo, selectedPosicionType, setSelectedPosicionType, numRowsDisplayedStocks, setNumRowsDisplayedStocks }) => {

return (
    <div>
    <DropdownSection {...{periodos, posicionTypes, selectedPeriodo, setSelectedPeriodo, selectedPosicionType, setSelectedPosicionType}}/>
    <TableSection {...{positionSections, numRowsDisplayedStocks, setNumRowsDisplayedStocks}}/>
 
    </div>   
);
            };

export default TableStocks;
