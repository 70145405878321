import React, { useEffect, useState } from 'react';


const FundDescriptionSection = ({ content, previewWordCount }) => {
  if (content==null | content==undefined) {
    content= "NO DESCRIPTION DATA"
  }
    return (
          <div>
            {content}
            <div className="text-center">
            </div>   
          </div>
    )
  }
    /*
    return (
      <div>
        {isExpanded ? (
          <div>
            {content}
            <div className="text-center">
              <button className="btn btn-primary" onClick={() => setIsExpanded(false)}>Ver menos</button>
            </div>   
          </div>
        ) : (
          <div>
            {previewContent+"."}
            <br />
            <br />
            {words.length > previewWordCount && (
              <div className="text-center">
                <button className="btn btn-primary" onClick={() => setIsExpanded(true)}>Ver más</button>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };*/

  export default FundDescriptionSection;