import { Spinner } from 'react-bootstrap';


const SpinnerLoading = () => {
    return (
      <div className="d-flex justify-content-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Cargando datos...</span>
        </Spinner>
      </div>
    );
  };
  
  export default SpinnerLoading;