// TradingViewWidget.jsx

import React, { useEffect, useRef } from 'react';

let tvSymbolScriptLoadingPromise;

export default function TVStockSymbol({ticker}) {
  const onLoadScriptRef = useRef();

  useEffect(() => {
    if (!tvSymbolScriptLoadingPromise) {
      tvSymbolScriptLoadingPromise = new Promise((resolve) => {
        const script = document.createElement('script');
        script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-symbol-info.js';
        script.async = true;
        script.onload = resolve;
        script.innerHTML = JSON.stringify({
          "colorTheme": "light",
          "isTransparent": true,
          "width": "100%",
          "symbol": ticker,
          "locale": "en"
        });

        onLoadScriptRef.current.appendChild(script);
      });
    }


    tvSymbolScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

    return () => onLoadScriptRef.current = null;
  }, [ticker]);

  return (
    <div className='tradingview-widget-container' ref={onLoadScriptRef}>
      <div className='tradingview-widget-container__widget'></div>
    </div>
  );
}