import { useEffect, useState } from 'react';
import { FUND_PERIODS_URL, FUND_DETAILS_URL, FUND_POSITIONS_URL } from '../utils/Constants';
import { useFetch } from './fetchURL';
import { fetchApi } from '../utils/DataFetch';
import { useNavigate } from 'react-router-dom';


export const useFetchFund = (fund_key, fund_key_name, location) => { 
  const [quarters, setQuarters] = useState([]);
  const [clases, setClases] = useState([]);
  const [fundData, setFundData] = useState([]);
  const [selectedQuarter, setSelectedQuarter] = useState(null);
  const [selectedClase, setSelectedClase] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isPositionsLoading, setIsPositionsLoading] = useState(true);
  const [positionsData, setPositionsData] = useState([]);
  const [fundDetails, setFundDetails] = useState([]);
  const [fetchUrl, setFetchUrl] = useState(FUND_PERIODS_URL(fund_key, fund_key_name));
  const [fondo_base_id, setFondoBaseID] = useState(null);

  const { data: quarter_values } = useFetch(fetchUrl);
  const navigate = useNavigate();



  useEffect(() => { // Every time that the location (URL) changes, we reset the state (or if the selected quarter changes)
    setIsLoading(true);
    setIsPositionsLoading(true);
    setQuarters([]);
    setSelectedQuarter(null);
    setClases([]);
    setSelectedClase(null);
    setFundData([]);
    setFundDetails([]);
    setFetchUrl(FUND_PERIODS_URL(fund_key, fund_key_name));
}, [location]);

  useEffect(() => { // If 'quarter_values' changes, it means that we have fetched the quarters for the fund and we can set the state
    if (quarter_values && quarter_values['periodos'] && quarter_values['periodos'].length === 0) { //Fondo doesn't exist
      navigate('/404'); 
    }
    else if (quarter_values && quarter_values['periodos'] && quarter_values['periodos'].length > 0) {
      setQuarters(quarter_values['periodos']);
      setSelectedQuarter(quarter_values['periodos'][0])
    }
  }, [quarter_values]);


  useEffect(() => { // When selected quarter changes, fetch new funds data
    if (selectedQuarter) {
      setIsLoading(true);
      fetchApi(FUND_DETAILS_URL(fund_key, fund_key_name, selectedQuarter)).then(data => {
      setFondoBaseID(data[0].fondo_base_id)
      setFundData(data);
      const uniqueClases = new Set(data.map((item) => item.nombre_clase));
      const fetchedClases = Array.from(uniqueClases).map((label) => ({
        label,
        index: data.findIndex((item) => item.nombre_clase === label),
        num_participes: data[data.findIndex((item) => item.nombre_clase === label)].num_participes, //http://localhost:3001/fund/5131_0_FI -> order not working. Why?
      }));

      setClases(Array.from(uniqueClases));
      // Set the default clase depending on the value of "num_participes". If num_participes !=0, set the clase with the highest num_participes, if not, set the first clase
      const defaultClase = fetchedClases.reduce((prev, current) => (prev.num_participes > current.num_participes) ? prev : current);
      setSelectedClase(defaultClase.label);
      document.title = `${data[0].fondo_nombre} - InFondos`;
      const matchedDetail = data.find(item => item.nombre_clase === defaultClase.label);
      setFundDetails(matchedDetail);
      setIsLoading(false);
      });

    }
  }, [selectedQuarter]);

  useEffect(() => { // When selected quarter changes, fetch new funds-positions data
    if (selectedQuarter && fondo_base_id) {
        setIsPositionsLoading(true);
        fetchApi(FUND_POSITIONS_URL(fondo_base_id, selectedQuarter)).then(data => {
        setPositionsData(data);
        setIsPositionsLoading(false);
      });
    }
  }, [selectedQuarter, fondo_base_id]);
    
  //The array [] at the end of useEffect indicates that it should run once and not re-run when component updates
  
  
    useEffect(() => { // If the selected clase changes, we update the fund details
      if (selectedClase) { 
        const filteredData = fundData.filter(
          (item) => item.nombre_clase === selectedClase
        );
        if (filteredData.length > 0) {
          setFundDetails(filteredData[0]);
        }
        setIsLoading(false);
      }
    }, [selectedClase]);
  


  return { quarters, clases, fundDetails, positionsData, selectedQuarter, selectedClase, setSelectedQuarter, setSelectedClase, isLoading, isPositionsLoading };
};
