import React, { useEffect, useState } from 'react';
import {Row, Col, Table } from 'react-bootstrap';
import TradingViewSymbolProfileWidget from '../trading_view/TVStockProfile';
import TVStockChartSimple from '../trading_view/TVStockChartSimple';
import TVStockFundamentals from '../trading_view/TVStockFundamentals';
import TVStockSymbol from '../trading_view/TVStockSymbol';
import SpinnerLoading from '../utils/SpinnerLoading';


//TODO: MOVER A HOOK y estandarizar!
  //TO-DO: Problemas al ir de una posición a otra (usando el buscador). Carga con el fondo_base_id (y periodo_id) de la posición anterior. Dos requests.

  const RenderTVSections = ({ticker}) => {
    const [show_section, setShowSection] = useState(false);
    const [show_fundamentales, setShowFundamentales] = useState(false);


    return (
      <>
        <Row className="mt-4">
          <TVStockSymbol ticker={ticker} />
        </Row>
        <Row className="my-5">
          <Col md={3} xs={12}>
            <h3>Details</h3>
          </Col>
          <Col md={4} xs={12} className="mb-3">
              <button className="btn btn-primary me-5" onClick={() => setShowSection(!show_section)}>
                {show_section ?  `Hide Price` : `Show Price`}
              </button>
              </Col>
            <Col md={4} xs={12}>
              <button className="btn btn-primary me-5" onClick={() => setShowFundamentales(!show_fundamentales)}>
                {show_fundamentales ?  `Hide Information` : `Show Information`}
              </button>
            </Col>
          </Row>
          <Row className="my-3" style={{ display: show_section ? 'block' : 'none' }}>
            <Col>
              { <TVStockChartSimple ticker={ticker}/>}
            </Col>
          </Row>
          <Row
            className="my-5"
            style={{
              display: show_fundamentales ? 'flex' : 'none'}}
          >
            <Col md={7}>
              <TradingViewSymbolProfileWidget ticker={ticker} />
            </Col>
            <Col md={5}>
              <TVStockFundamentals ticker={ticker} />
            </Col>
          </Row>

      </>
    );
  }

  const StockSection= ({tv_ticker, isinPosicion, fondo_base_id, periodo}) => {
    // Get the fund ID from the URL
  const [isPositionLoading, setIsPositionLoading] = useState(true);
  const [position_details, setPositionDetails] = useState(null);
  const [positionDetalleError, setPositionDetalleError] = useState(null);

useEffect(() => {
  let isMounted = true; // flag to track if the component is mounted
  setIsPositionLoading(true); // start loading
  setPositionDetalleError(null); // clear any previous errors
  //TODO: MOve to a hook
  const PositionDetalleUrl = `https://apiv2.infondos.com/posicion_detalle?isin_posicion=${isinPosicion}&fondo_base_id=${fondo_base_id}&periodo_id=${periodo}`

  const fetchPositionData = async () => {
    try {
      const response = await fetch(PositionDetalleUrl);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (isMounted) {
        setPositionDetails(data[0])
      }
    } catch (error) {
      if (isMounted) {
        setPositionDetalleError(error.message);
        //setIsPositionLoading(false); // stop loading if an error occurred
      }
    }
    //console.log(data[0])
  }
  if (tv_ticker===undefined && fondo_base_id && isinPosicion && periodo) {
    fetchPositionData();
  }
}, [fondo_base_id, periodo]);


useEffect(() => {
  if (position_details) {
      document.title = `${position_details.name} - InFondos`;
      setIsPositionLoading(false); // stop loading once the data is fetched
  }
}, [position_details]);


  const RenderDBSections = () => {
    return isPositionLoading ? (
      <SpinnerLoading/>
    ) : (
      <>
        <Row className="mt-4">
          <Col>
            <h1>{position_details.name}</h1>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <Table striped bordered hover responsive size="sm">
              <thead>
                <tr>
                  <th>Ticker</th>
                  <th>ISIN</th>
                  <th>Type</th>
                  <th>Sector</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{position_details.ticker}</td>
                  <td>{position_details.isin_posicion}</td>
                  <td>{position_details.tipo_detalle}</td>
                  <td>{position_details.sector}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
          {/*
        <Row className="mt-4">
          <Col>
            <h4>Exchange Tickers</h4>
            {renderTickers(position_details.exchange_ticker)}
          </Col>
    </Row> */}
      </>
    );
      }

  

  return tv_ticker ? <RenderTVSections ticker={tv_ticker}/>: <RenderDBSections/>;
};



export default StockSection;
