import { useEffect, useState } from 'react';
import { SEARCH_URL } from '../utils/Constants';
import { fetchApi } from '../utils/DataFetch';

/**
 * Hook to fetch options based on user input with debounce to reduce API calls.
 * @param {string} name - The current input from the user to search for.
 * @return {{optionsList: Array, isLoading: boolean}} The state of options list and loading status.
 */
export const useFetchOptions = (name) => {
  const [optionsList, setOptionsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Debounce timer to limit API calls.
    const timerId = setTimeout(() => {
      if (name.length > 3) {
        setIsLoading(true);
        fetchApi(SEARCH_URL(name)).then(data => {
          setOptionsList(data);
          setIsLoading(false);
        });
      }
    }, 250); // 250 ms debounce time

    // Cleanup function to clear the timer when the component is unmounted or before the effect runs again.
    return () => clearTimeout(timerId);
  }, [name]);

  return { optionsList, isLoading };
};
