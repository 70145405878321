// fetchURL.js
import { useState, useEffect } from 'react';
import { fetchApi } from '../utils/DataFetch';

export const useFetch = (url) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchApi(url)
      .then(data_aux => setData(data_aux))
      .catch(error_aux => setError(error_aux.message));
  }, [url]);

  

  return { data, error };
};
