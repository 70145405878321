import {
    DropdownButton, Dropdown,
  } from 'react-bootstrap';
  
  const CustomDropdown = ({ items, selectedItem, setSelectedItem, title }) => (
    <DropdownButton 
      id="dropdown-basic-button"
      title={selectedItem ? selectedItem : title}
      onSelect={(label) => {
        setSelectedItem(label);
      }}
      className='me-5'
    >
      {items.map((item, index) => (
        <Dropdown.Item key={index} eventKey={item}>
          {item}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
  
  export default CustomDropdown;
  