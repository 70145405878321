import { Table } from 'react-bootstrap';
import {formatLabel, formatValue} from '../utils/DataHelpers';
import { porcentajeKeys, nonEurKeys } from '../utils/Constants';


const TableComponent = ({table_data, table_keys, keys}) => {
    return (
    <div style={{overflowX: 'auto', width: '100%'}}>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th className="table-data" >Periodo</th>
            {table_keys.map((header) => 
                <th className="table-data" key={header}>{formatLabel(header)}</th>
            )}
          </tr>
        </thead>
        <tbody>
          {keys.map((key) => (
            <tr key={key}>
              <td className="table-data"><strong>{key}</strong></td>
              {table_keys.map((header) => (
                <td className="table-data" key={header}>
                  {
                    table_data[header] !== undefined && table_data[header][key] !== undefined ? formatValue(table_data[header][key], header, porcentajeKeys, nonEurKeys) : ''
                  }
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
    );
};

export default TableComponent;