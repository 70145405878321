import React from 'react';
import {
  Button,
  Row,
  Col,
  Tabs, Tab, Table
} from 'react-bootstrap';
import '../css/LandingPage.css';
import CustomDropdown from '../utils/CustomDropdown';
import { Link } from 'react-router-dom';
//import json_funds from './files/summary_fondos.json';
import { prettyPrintNumber } from '../utils/DataHelpers';

export const DropDownDefault = ({periodos, selectedPeriodo, setSelectedPeriodo, types, selectedType, setSelectedType}) => {
    return (
    <>
    <Col md={2} xs={6}>
        <CustomDropdown
        items={periodos}
        selectedItem={selectedPeriodo}
        setSelectedItem={setSelectedPeriodo}
        title={selectedPeriodo}
        />
    </Col>
    <Col md={3}>

        <CustomDropdown
        items={types}
        selectedItem={selectedType}
        setSelectedItem={setSelectedType}
        title="Select a Fund Type"
        />
    </Col>
    </>
    )
}

const DropDownFunds = ({periodos, selectedPeriodo, setSelectedPeriodo, fundTypes, selectedFundType, setSelectedFundType}) => {
return (
    <Row className="my-5">
    <hr />
    <Col md={4} xs={6} >
        <h3>Top Funds</h3>
    </Col>
    <DropDownDefault periodos={periodos} selectedPeriodo={selectedPeriodo} setSelectedPeriodo={setSelectedPeriodo} 
    types={fundTypes} selectedType={selectedFundType} setSelectedType={setSelectedFundType}/>
    </Row>
)
}

export const TableButton = ({funds, numRowsDisplayed, setNumRowsDisplayed, all_rows= false}) => {
    return (
    <div className="text-center">
        <Button
            onClick={() => {
                setNumRowsDisplayed(5);
            }}
            disabled={funds ? numRowsDisplayed <= 5 : true}
            className="me-2"
            >
                See less
        </Button>
        <Button
            onClick={() => {
                if (all_rows){
                    setNumRowsDisplayed(funds.length);
                }
                else{
                    if (funds && numRowsDisplayed + 5 >= funds.length) {
                        setNumRowsDisplayed(funds ? funds.length : 0);
                        } 
                    else {
                        setNumRowsDisplayed(numRowsDisplayed + 5);
                    }
                }
            }}
            disabled={funds ? numRowsDisplayed >= funds.length : true}
            className="me-2"
            >
                See more
        </Button>

    </div>
    )
}

const TableSection = ({fundSections, numRowsDisplayed, setNumRowsDisplayed}) => {
    return (
        <Row className="my-3">
        <Col>
        {fundSections && fundSections.length > 0 && (
            <Tabs defaultActiveKey={fundSections[0].title} className="mb-3 text-nowrap">
            {fundSections.map((section, index) => (
                <Tab key={index} eventKey={section.title} title={section.title}>
                <div className="overflow-auto">
                <Table striped bordered hover responsive size="sm">
                    <thead>
                    <tr>
                        <th>Fund Name</th>
                        <th>ISIN</th>
                        <th>Value</th>
                    </tr>
                    </thead>
                    <tbody>
                    {section.funds 
                        ? section.funds.slice(0, numRowsDisplayed).map((fund, idx) => (
                            <tr key={idx}>
                            <td>
                                <Link to={`/fund/${fund.fondo_base_id}`}>{fund.fondo_nombre}</Link>
                            </td>
                            <td>
                                <Link to={`/fund/${fund.fondo_base_id}`}>{fund.isin_fondo}</Link>
                            </td>
                            <td>
                                {prettyPrintNumber(parseFloat(fund.value), 'es-ES')}
                                {section.title === "Total Partícipes"
                                ? ""
                                : section.title === "Total Portfolio"
                                ? "€"
                                : "%"}
                            </td>
                            </tr>
                        ))
                        : <tr></tr>
                    }
                    </tbody>

                </Table>
                </div>
                <TableButton  funds={section.funds} numRowsDisplayed={numRowsDisplayed} setNumRowsDisplayed={setNumRowsDisplayed} />
                </Tab>
            ))}
            </Tabs>
        )}
        </Col>
        </Row>
    )
}

//TODO: Review parameters
const TableFunds = ({ fundSections, periodos, fundTypes, selectedPeriodo, setSelectedPeriodo, selectedFundType, setSelectedFundType, numRowsDisplayed, setNumRowsDisplayed }) => {

return (
    <div>
        <DropDownFunds {...{periodos, selectedPeriodo, setSelectedPeriodo, fundTypes, selectedFundType, setSelectedFundType}}/>
        <TableSection {...{fundSections, numRowsDisplayed, setNumRowsDisplayed}}/>
        </div>   
);
            };

export default TableFunds;
