import React, { useEffect, useState } from 'react';
import {Row, Col } from 'react-bootstrap';



const DervidadosSection = ({fundDetails}) => {
    const [show_derivados, setShowDerivados] = useState(false);
    return (
      <>
        <Row className="bg-light p-3 my-3">
          <Col>
            <p>
              {fundDetails.operativa_derivados}
            </p>
          </Col>
        </Row>
        <Row className="my-3">
          <Col>
          <div className="text-center">
            <button className="btn btn-primary" onClick={() => setShowDerivados(!show_derivados)}>
                  {show_derivados ? 'Hide Derivatives' : 'Show Derivatives'}
            </button>
          </div>
          </Col>
        </Row>
        { show_derivados ? 
        <Row className="my-3">
          <Col>
          <div className="text-center">
            <p><strong>Coming soon...</strong></p>
              <div className="loader-center"></div>
          </div>
          </Col>
        </Row> : 
        null }
    </>
    )
            }

export default DervidadosSection;