import React, { useEffect, useState } from 'react';


const CommentarySection = ({ content, previewLength }) => {
    const [isExpanded, setIsExpanded] = useState(false);  
    const previewContent = content.slice(0, previewLength);
    // Count the number of lines in content
    const contentLength = content.length;
    if (contentLength <= previewLength) {
      return <div>{content}</div>;
    }  
    return (
      <div>
        {isExpanded ? (
          <div>
            {content}
            <div className="text-center">
                <button className="btn btn-primary" onClick={() => setIsExpanded(false)}>See less</button>
            </div>   
     </div>
        ) : (
          <div>
            {previewContent}
            <div className="text-center">
              <button className="btn btn-primary" onClick={() => setIsExpanded(true)}>See more</button>
            </div>   
          </div>
        )}
      </div>
    );
  };

  export default CommentarySection;