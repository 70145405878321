import { Table } from 'react-bootstrap';
import { formatLabel, formatValue } from '../utils/DataHelpers';

const TableSingleRow = ({ table_data, table_keys, data }) => {
  // Función para parsear la rentabilidad y encontrar la fecha más reciente
  const getMostRecentDateFromRentabilidad = (rentabilidadStr) => {
    try {
      const rentabilidadStrFixed = rentabilidadStr.replace(/'/g, '"');
      const rentabilidadObj = JSON.parse(rentabilidadStrFixed);
      const dates = Object.keys(rentabilidadObj).filter(date => date.includes('-')); // Asegura fechas completas
      if (dates.length === 0) {
        return null; // Maneja el caso de un arreglo vacío adecuadamente
      }
      return dates.reduce((a, b) => (a > b ? a : b)); // Encuentra la fecha más reciente
    } catch (e) {
      console.error("Error parsing rentabilidad:", e);
      return null; // Devuelve null si hay un error en el parseo
    }
  };

  // Determinar si periodo_id tiene el formato "YYYY_QX"
  const isPeriodoFormat = /^(\d{4}_Q[1-4])$/.test(data.periodo_id);
  // Si es necesario, obtener la fecha más reciente de rentabilidad parseando el string a un objeto
  const mostRecentDate = isPeriodoFormat ? getMostRecentDateFromRentabilidad(data.rentabilidad) : null;



  return (
    <div style={{ overflowX: 'auto', width: '100%' }}>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th className="table-data">Periodo</th>
            {table_keys.map((header) => (
              <th className="table-data" key={header}>{formatLabel(header)}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr key={data.año || data.periodo_id}>
            <td className="table-data"><strong>{data.año || data.periodo_id}</strong></td>
            {table_keys.map((header) => (
              <td className="table-data" key={header}>
                {['num_participes', 'num_participaciones'].includes(header)
                  ? (table_data[header] ? formatValue(table_data[header][isPeriodoFormat ? mostRecentDate : data.periodo_id], header) : '')
                  : (table_data[header] ? formatValue(table_data[header][isPeriodoFormat ? mostRecentDate : data.periodo_id], header, data.moneda) : '')
                }
              </td>
            ))}
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default TableSingleRow;
