import React from 'react';
import { Button, Row, Col, ListGroup } from 'react-bootstrap';
import SpinnerLoading from '../utils/SpinnerLoading';


const letters = ['A','B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'O','P','Q','R','S','T','U','V','W','X','Y','Z','OTHER'];

const LetterSelector = ({selectedLetter, onSelectLetter, isLoading, fundsToShow, LinkComponent }) => {
  return (
    <>
      {letters.map((letter, index) => (
        <Button
          key={index}
          variant="outline-primary"
          className="m-1"
          onClick={() => onSelectLetter(letter)}
        >
          {letter}
        </Button>
      ))}
      {selectedLetter && (
        <Row className="mt-4 funds">
          <Col>
            {isLoading ? (
              <SpinnerLoading />
            ) : (
              <>
                <Row>
                  {fundsToShow.length > 0 ? (
                    fundsToShow.map((fund, idx) => (
                      <Col key={idx} md={4}>
                        <ListGroup.Item key={idx} action>
                          <LinkComponent to={`/fund_by_name/${fund.internal_id}`}>{fund.name}</LinkComponent>
                        </ListGroup.Item>
                      </Col>
                    ))
                  ) : (
                    <Col>
                      <p>No funds to show.</p>
                    </Col>
                  )}
                </Row>
              </>
            )}
          </Col>
        </Row>
      )}
    </>
  );
};

export default LetterSelector;
