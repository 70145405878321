import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function GoogleAnalyticsReporter() {
    const location = useLocation();
  
    useEffect(() => {
      window.gtag('event', 'page_view', {
        'page_path': location.pathname
      });
    }, [location]);
  
    return null;
  }