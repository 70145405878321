// TradingViewWidget.jsx

import React, { useEffect, useRef } from 'react';

let tvTickerTapesScriptLoadingPromise;


export default function TVTickerTape() {
  const onLoadScriptRef = useRef();
 
  useEffect(() => {
    if (!tvTickerTapesScriptLoadingPromise) {
      tvTickerTapesScriptLoadingPromise = new Promise((resolve) => {
        const script = document.createElement('script');
        script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js';
        script.async = true;
        script.onload = resolve;
        script.innerHTML = JSON.stringify({
          "colorTheme": "light",
          "isTransparent": true,
          "largeChartUrl": "",
          "displayMode": "adaptive",
          "locale": "en",
          "simbols" : [
              {
                "proName": "FOREXCOM:SPXUSD",
                "title": "S&P 500"
              },
              {
                "proName": "FX_IDC:EURUSD",
                "title": "EUR/USD"
              },
              {
                "description": "DAX",
                "proName": "XETR:DAX"
              },
              {
                "description": "NASDAQ",
                "proName": "NASDAQ:NDX"
              },
              {
                "description": "IBEX 35",
                "proName": "BME:IBC"
              },
              {
                "description": "10 YEAR US BOND",
                "proName": "TVC:US10Y"
              }
            ],
        });

        onLoadScriptRef.current.appendChild(script);
      });
    }
    tvTickerTapesScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

    return () => onLoadScriptRef.current = null;
  }, []);

  return (
    <div className='tradingview-widget-container' ref={onLoadScriptRef}>
      <div className='tradingview-widget-container__widget'></div>
    </div>
  );
} 