import React, { useState, useEffect } from 'react';


export const useSetLanguage = () => { 

const [language, setLanguage] = useState('en');  // Default to English

useEffect(() => {
const browserLang = navigator.language.startsWith('es') ? 'es' : 'en';
setLanguage(browserLang);
}, []);

return language
}
