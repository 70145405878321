import React, { useState, useEffect } from 'react';
import { faker } from '@faker-js/faker';
import { Pie } from 'react-chartjs-2'; 
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { processCountryPositions } from './ChartHelpers';

import { flatly_color_palette } from '../Constants';

import chroma from 'chroma-js';


ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  ChartDataLabels
);


function PieChart({ processedData }) { 
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
          setInitialized(true);
        }, 500); // Adjust the timing as necessary for your layout
    
        return () => clearTimeout(timer);
    }, []);

    const options = {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            datalabels: {
              color: '#fff', // you can change this to any color you need for visibility
              formatter: (value, context) => {
                const label = context.chart.data.labels[context.dataIndex];
                const percentage = context.dataset.data[context.dataIndex];
                if (percentage<=1)
                    return ''
                return shouldAbbreviate(label, percentage) ? label.substr(0, 3) + '...' : label;
              },
              font: {
                weight: 'bold'
              }
            },
            tooltip: {
              callbacks: {
                  label: function(context) {
                      let label = context.dataset.label || '';
                      const value = context.parsed; // 'parsed' now contains the value for pie/doughnut charts
                      const yAxisSymbol = "%"; // Define your yAxis symbol (e.g., '%', '$', etc.)
  
                      if (label) {
                          label += ': ';
                      }
                      if (value !== undefined) {
                          label += `${value} ${yAxisSymbol}`;
                      }
                      return label;
                  },
              },
          },
      },
  };

    const shouldAbbreviate = (label, percentage) => {
        const maxChars = Math.floor(percentage * 2); 
        return label.length > maxChars;
      };
      

    //const themeColors = ['#2C3E50', '#18BC9C', '#3498DB', '#F39C12', '#E74C3C']; //FLATLY

    const generateThemeBasedColors = (count) => {
        // This creates a color scale that includes the theme colors and interpolates between them
        const scale = chroma.scale(flatly_color_palette).mode('lch').colors(count);
        return scale;
    };
      
    const pieData = {
        labels: Object.keys(processedData),
        datasets: [
          {
            data: Object.values(processedData),
            backgroundColor: generateThemeBasedColors(Object.keys(processedData).length),
            hoverBackgroundColor: generateThemeBasedColors(Object.keys(processedData).length),
          }
        ]
    };
      
    return (
        initialized ? <Pie options={options} data={pieData} /> : null
    );
};

export default PieChart;
