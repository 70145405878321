import { useEffect, useState } from 'react';
import { LETTER_URL } from '../utils/Constants';
import { fetchApi } from '../utils/DataFetch';

/**
 * Hook to fetch options based on user input with debounce to reduce API calls.
 * @param {string} country - The name of the country
 * @param {string} letter - The letter to search for
 * @return {{fundList: Array}} The state of options list and loading status.
 */
export const useFetchLetter = (country, letter) => {
  const [fundList, setFundList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    if (letter!==''){
    setIsLoading(true);
    fetchApi(LETTER_URL(country, letter)).then(data => {
      setFundList(data);
      setIsLoading(false)
    });
  }
  }, [letter]);

  return {fundList, isLoading} ;
};
