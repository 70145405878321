import React, { useState, useEffect, useRef } from 'react';
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import './css/LandingPage.css';
import TVTickerTape from './trading_view/TVTickerTape';
import SidePanel from './SidePanel';
import { LandingPageCarousel } from './landing/LandingCarousel';
import { LandingPageInfo } from './landing/LandingInfo';
//TODO: Move to other component

//TODO: IMPLEMENTAR SECCIÓN ÚLTIMOS FONDOS AÑADIDOS
//TODO: SEPARAR USA-ESPAÑA EN SECCIONES.
//TODO: LANDING SÓLO TENDRÁ UN BUSCADOR Y UN CUADRO CON LOS ÚLTIMOS FONDOS AÑADIDOS.
//TODO: DOCUMENTAR
//TODO: AÑADIR SECCIÓN COMENTARIOS
//TODO: AÑADIR SECCIÓN FONDOS + VISITADOS


const LandingPage = () => {

//https://chat.openai.com/share/fd1f26a1-397f-4230-9faa-586f940b1232 -> Added Sentence to Landing
  return (
    <Container className="mt-4">
        <Row className="my-3">
          <TVTickerTape />
        </Row>
        <Row className="my-3">
      <Col>
      <LandingPageCarousel />
      </Col>
    </Row>
    <Row className="my-3">
      <SidePanel />
    </Row>
    <LandingPageInfo/>

    </Container>
  );
};

export default LandingPage;
