import React, { useState, useEffect } from 'react';
import { Table, Tab, Nav, Spinner, Tooltip, OverlayTrigger } from 'react-bootstrap';
import SpinnerLoading from '../utils/SpinnerLoading';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { formatPercentage, processPercentageRow, processTotalRow, sortDataByPercentage, style, apply_percentage_filter} from '../utils/DataHelpers';
import { TableButton } from '../landing/TableFunds';

//TODO: ADD MAIN_EXCHANGE A INDEX!
//TODO: ADD OTHER SOURCE WHEN A HOLDING IS NOT FOUND IN TRADINGVIEW!

const PositionsTable = ({ data, periodoId, loading, isUSAfund }) => {
  const [activeTab, setActiveTab] = useState('all');
  const [numRowsDisplayed, setNumRowsDisplayed] = useState(5);


  const filteredPositions = sortDataByPercentage(data.filter((item) => item.periodo_id === periodoId));
  const sells = filteredPositions.filter((item) => Math.abs(item.accion_diff_percentage)>=2 & item.accion_diff_percentage<0 )
  const buys = filteredPositions.filter( (item) => Math.abs(item.accion_diff_percentage)>=2 & item.accion_diff_percentage>0 )
  const all = filteredPositions.filter((item) => item.accion_diff_percentage!=-100 | (item.estado !== null && item.estado !== undefined && item.estado!='VENTA'));

  const tableHeaderStyle = {
    whiteSpace: 'nowrap',
    // Add any other styles you want to apply to the table headers here
  };

 
  const getTable = (positions, eventkey) => {
    return (
      <Tab.Pane eventKey={eventkey}>
      <div style={{overflowX: 'auto', width: '100%'}}>
        <Table striped bordered hover size="sm">
          {/* Table structure */}
          <thead>
            <tr>
              <th>Name</th>
              <th style={tableHeaderStyle}>Fund %</th>
              <th style={tableHeaderStyle}>Fund Total</th>
              <th style={tableHeaderStyle}>Activity %<AiOutlineInfoCircle className="info-icon" title="Percentage difference in the number of shares in the fund for the holding (No value below 2% is shown)." /></th>
              <th style={tableHeaderStyle}>Price Diff %<AiOutlineInfoCircle className="info-icon" title="Price difference in the price of the stock between this and last period.We don't know the exact moment in which a fund started a position so we can't calculate the estimated return." /></th>
              <th>Since<AiOutlineInfoCircle className="info-icon" title="Minimun period since which the fund is holding the position (Min available period for spanish funds: 2021_S1. Min available period for US funds: 2023_Q1)" /></th>
              <th>Type</th>
              <th>ISIN</th>
              {!isUSAfund ? <th>Ticker</th>: null}
              <th>Country</th>
              {!isUSAfund ? <th>Sector</th>: null}              
            </tr>
        </thead>
          <tbody>{getRows(positions)}</tbody>
        </Table>
        </div>
        <TableButton  funds={positions} numRowsDisplayed={numRowsDisplayed} setNumRowsDisplayed={setNumRowsDisplayed} all_rows={true} />
      </Tab.Pane>
    )
  };

  const getRows = (positions) => {
    // Conditional rendering based on the presence of funds.
    return positions
      ? positions.slice(0, numRowsDisplayed).map((item, index) => (
      <tr key={index}>
        <td>
              <a href={`/position/${item.isin_posicion}`}>{item.name}</a>
            </td>
            <td>{processPercentageRow(item)}</td>
            <td>{processTotalRow(item)}</td>
            <td style={style(apply_percentage_filter(item.accion_diff_percentage))}>
            {formatPercentage(apply_percentage_filter(item.accion_diff_percentage))}
            </td>
            <td style={style(apply_percentage_filter(item.isin_price_diff_percentage))}>
            {formatPercentage(apply_percentage_filter(item.isin_price_diff_percentage))}
            </td>
            <td>{item.since_periodo}</td>
            <td>{item.tipo_detalle}</td>
            <td>{item.isin_posicion}</td>
            {!isUSAfund ?             <td>{(item.isin_main_ticker !== null && item.isin_main_ticker !== undefined) ? item.isin_main_exchange+':'+item.isin_main_ticker : ''}</td>
  : null}              
            <td>{item.isin_country}</td>
            {!isUSAfund ? <td>{item.isin_sector}</td>  : null}              
      </tr>
        ))
      : <tr></tr>; // Returns an empty row if there are no funds.
  };


  if (loading) {
    return (
      <SpinnerLoading/>
    );
  }

  return (
    <Tab.Container activeKey={activeTab} onSelect={(key) => setActiveTab(key)}>
      <Nav variant="tabs" className="mb-3">
        <Nav.Item>
          <Nav.Link eventKey="all">All</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="sells">Sells</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="buys">Buys</Nav.Link>
        </Nav.Item>
      </Nav>
      <Tab.Content>
        {getTable(all, 'all')}
        {getTable(sells, 'sells')}
        {getTable(buys, 'buys')}
      </Tab.Content>
    </Tab.Container>
  );
};

export default PositionsTable;
