import { useEffect, useState } from 'react';
import { SUMMARY_LATEST_URL, SUMMARY_URL } from '../utils/Constants';
import { fetchApi, fetchJSON } from '../utils/DataFetch';


export const useFetchSummary = () => { 
  const [selectedPeriodo, setSelectedPeriodo] = useState(null);
  const [periodos, setPeriodos] = useState([]);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [currentJsonData, setCurrentJsonData] = useState({});
  const [posicionTypes, setPosicionTypes] = useState([]);
  const [fundTypes, setFundTypes] = useState([]);
  const [fundSections, setFundSections] = useState([]);
  const [positionSections, setPositionSections] = useState([]);
  const [selectedPosicionType, setSelectedPosicionType] = useState("Todas");
  const [selectedFundType, setSelectedFundType] = useState("Fondos Activos");

  useEffect(() => {
    fetchApi(SUMMARY_LATEST_URL)
      .then(data => fetchJSON(data.url))
      .then(jsonData => {
        setPeriodos(Object.values(jsonData['periodos']));
        setSelectedPeriodo(Object.values(jsonData['periodos'])[0]); // setting default value
        setCurrentJsonData(jsonData);
      });
    }, []);

    // Add a separate effect for updating `isFirstLoad`
  useEffect(() => {
    if (currentJsonData && Object.keys(currentJsonData).length > 0) {
      setIsFirstLoad(false);
    }
  }, [currentJsonData]);

  useEffect(() => {
    if (selectedPeriodo !== null && !isFirstLoad) {
      // Request to the previous endpoint when periodo is max (To take it from Cache)
      fetchApi(SUMMARY_URL(selectedPeriodo))
        .then(data => fetchJSON(data.url))
        .then(jsonData => {
          setCurrentJsonData(jsonData);
        });
    }
  }, [selectedPeriodo]);


  useEffect(() => {
    if (currentJsonData && !isFirstLoad) { // make sure currentJsonData is defined
      setPosicionTypes(Object.keys(currentJsonData['posiciones_total_tipo']['Todos']));
      setFundTypes(Object.keys(currentJsonData['posiciones_total_tipo'])); 
      setFundSections([
        {
          title: 'Rentabilidad',
          funds: currentJsonData.fondo_rentabilidad[selectedFundType]
        },
        {
          title: 'TER',
          funds: currentJsonData.fondo_ter[selectedFundType]
        },
        {
          title: 'Rotación Cartera',
          funds: currentJsonData.fondo_rotacion[selectedFundType]
        },
        {
          title: 'Total Partícipes',
          funds: currentJsonData.fondo_participes[selectedFundType]
        },
        {
          title: 'Total Portfolio',
          funds: currentJsonData.fondo_portfolio[selectedFundType]
        },
      ]);
  
      setPositionSections([
        {
          title: 'Apariciones',
          funds: currentJsonData.posiciones_count_tipo[selectedFundType][selectedPosicionType]
        },
        {
          title: 'Ventas',
          funds: currentJsonData.posiciones_sell_tipo[selectedFundType][selectedPosicionType]
        },
        {
          title: 'Compras',
          funds: currentJsonData.posiciones_buy_tipo[selectedFundType][selectedPosicionType]
        },
        {
          title: '% en el Fondo',
          funds: currentJsonData.posiciones_porcentaje_tipo[selectedFundType][selectedPosicionType],
        },
        {
          title: 'Total en el Fondo',
          funds: currentJsonData.posiciones_total_tipo[selectedFundType][selectedPosicionType],
        },
      ]);
    }
  }, [selectedPosicionType, selectedFundType, currentJsonData, isFirstLoad]);


  return { periodos, isFirstLoad, fundTypes, posicionTypes, fundSections, positionSections, selectedPeriodo, setSelectedPeriodo, selectedFundType, setSelectedFundType, selectedPosicionType, setSelectedPosicionType };
};
