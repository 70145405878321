// TradingViewWidget.jsx

import React, { useEffect, useRef } from 'react';

let tvFinancialsScriptLoadingPromise;


export default function TVStockFundamentals({ticker}) {
  const onLoadScriptRef = useRef();
 
  useEffect(() => {
    if (!tvFinancialsScriptLoadingPromise) {
      tvFinancialsScriptLoadingPromise = new Promise((resolve) => {
        const script = document.createElement('script');
        script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-financials.js';
        script.async = true;
        script.onload = resolve;
        script.innerHTML = JSON.stringify({
          "colorTheme": "light",
          "isTransparent": false,
          "largeChartUrl": "",
          "displayMode": "adaptive",
          "width": "100%",
          "height": 480,
          "symbol": ticker,
          "locale": "en"
        });

        onLoadScriptRef.current.appendChild(script);
      });
    }
    tvFinancialsScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

    return () => onLoadScriptRef.current = null;
  }, [ticker]);

  return (
    <div className='tradingview-widget-container' ref={onLoadScriptRef}>
      <div className='tradingview-widget-container__widget'></div>
    </div>
  );
} 