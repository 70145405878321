import React from 'react';

const RatingStars = ({ rating }) => {
  return (
    <div style={{ display: 'inline-block', marginLeft: '10px' }}>  
      {[...Array(5)].map((star, index) => {
        const ratingValue = index + 1;
        return (
          <span key={index} className="star">
            {ratingValue <= rating ? (
              <i className="fas fa-star" style={{ color: '#ffc107' }}></i> // Filled star
            ) : (
              <i className="far fa-star" style={{ color: '#ffc107' }}></i> // Empty star
            )}
          </span>
        );
      })}
    </div>
  );
};

export default RatingStars;
