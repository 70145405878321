import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Spinner, Dropdown, DropdownButton } from 'react-bootstrap';
import PositionsTable from './funds/PositionsTable';
import FundInformation from './funds/FundInformation';
import { useParams, useLocation, useNavigate } from 'react-router-dom'; 
import { parseCommentary } from './utils/DataHelpers';
import SidePanel from './SidePanel';
import SpinnerLoading from './utils/SpinnerLoading';
import CustomDropdown from './utils/CustomDropdown';
import FundDescriptionSection from './funds/FundDescriptionSection';
import DervidadosSection from './funds/DerivadosSection';
import CommentarySection from './funds/CommentarySection';
import {useFetchFund} from './hooks/fetchFund';
import FundPositionsCharts from './funds/FundPositionsCharts';
import InvestmentPerformanceCard from './utils/Charts/InvestmentPerformance';
import { CAGR_calculation, getFlagUrl } from './utils/DataHelpers';
import { Helmet } from 'react-helmet';


//TODO: Add URLs with links. 
//TODO: Only show CARD if there is enough data.
  //TO-DO: Improve regex (try to identify the  titles and substitles without spliting the text)
// TO-DO: Tratamiento de excepciones + error handling 

const FundDetails = () => {
  const { fundId, fundNameId, fundIsin } = useParams();
  var fund_key = null;
  var fund_key_name = null;
  if (fundId!==null && fundId!==undefined){
    fund_key = fundId
    fund_key_name = "fondo_base_id"
  }
  else if (fundNameId!==null && fundNameId!==undefined){
    fund_key = fundNameId
    fund_key_name = "fondo_nombre_id"
  }
  else if (fundIsin!==null && fundIsin!==undefined){
    fund_key = fundIsin
    fund_key_name = "isin_fondo"
  }
  //const navigate = useNavigate();
  const {
    quarters,
    clases,
    fundDetails,
    positionsData,
    selectedQuarter,
    selectedClase,
    setSelectedQuarter,
    setSelectedClase,
    isLoading,
    isPositionsLoading
  } = useFetchFund(fund_key, fund_key_name, location);

  const isUSAFund = (fundDetails.fondo_country === "USA")  ? true : false;

  const [show_graphs, setShowGraphs] = useState(false);



  // ADD Selector by gestor update. 
  // ALL OF THEM IN PARALLEL
  // ADD URL FOR GESTORA SELECTION
  // MOVE LAST PART TO A NEW ELEMENT
  return (
    <Container>
    <Row className="my-3">
      <SidePanel />
    </Row>
      {isLoading ? (
      <SpinnerLoading/>
    ) : (
      <>
      <Helmet>
        <title>{document.title}</title>
        <meta name="description" content={"Fund holdings of "+fundDetails.fondo_nombre} />
        <meta name="keywords" content={["cartera", "portfolio", "holdings", fundDetails.fondo_nombre, ]} />
      </Helmet>
        <Row className="my-3">
          <Col>
            <h1>{document.title = fundDetails.fondo_nombre} <img src={getFlagUrl(fundDetails.fondo_country)} alt="Flag" style={{ width: '40px', height: '30px'}} /></h1> 
          </Col>
        </Row>
        <Row className="my-2"> 
        <Col>
        { fundDetails.fecha_registro && fundDetails.performance_since_inception ? (
          <InvestmentPerformanceCard
              inceptionDate= {fundDetails.fecha_registro}
              cagr={CAGR_calculation(fundDetails.fecha_registro, parseFloat(fundDetails.performance_since_inception.replace(/,/g, '')))}
              performanceSinceInception={parseFloat(fundDetails.performance_since_inception.replace(/,/g, ''))}
            /> ) : null }
          </Col>
        </Row>
        <Row className="bg-light p-3 my-2">
          <Col>
              { <FundDescriptionSection content={fundDetails.politica_inversion} previewWordCount={2} />}
          </Col>
        </Row>
      <Row className="my-4">
      <hr />
        <Col md={4} xs={6}>
          <h3>Information</h3>
        </Col>
        <Col md={2} xs={6}>
            <CustomDropdown
            items={quarters}
            selectedItem={selectedQuarter}
            setSelectedItem={setSelectedQuarter}
            title="Select a Periodo"
          />
          </Col>
          <Col md={3}>
            <CustomDropdown
            items={clases}
            selectedItem={selectedClase}
            setSelectedItem={setSelectedClase}
            title="Select a Clase"
          />
          </Col>
      </Row>
      <FundInformation data={fundDetails} isUSAFund={isUSAFund}/>
      
      <Row className="my-3">
      <hr />
      <Col md={4} xs={6}>
          <h3>Holdings</h3>
        </Col>
        <Col md={2} xs={6}>
        <CustomDropdown
            items={quarters}
            selectedItem={selectedQuarter}
            setSelectedItem={setSelectedQuarter}
            title="Select a Periodo"
          />
          </Col>
          <Col md={3}>
          <CustomDropdown
            items={clases}
            selectedItem={selectedClase}
            setSelectedItem={setSelectedClase}
            title="Select a Clase"
          />
          </Col>
      </Row>
      <Row className="my-3">
        <Col>
          {<PositionsTable data={positionsData} periodoId={selectedQuarter} loading ={isPositionsLoading} isUSAfund={isUSAFund} />}
        </Col>
      </Row>
      <Row className="my-3">
      <div className="d-flex align-items-center">
          <h4 className='me-5'>Graphs</h4>
          <button className="btn btn-primary" onClick={() => setShowGraphs(!show_graphs)}>
            {show_graphs ? 'Hide Graphs' : 'Show Graphs'}
          </button>
      </div>
      </Row>
      { show_graphs ?  
            <FundPositionsCharts positionsData={positionsData} isUSAFund={isUSAFund} />
      : null }
      { isUSAFund ? 
          null : 
          <> 
      <Row className="my-2">
      <hr />
        <Col>
          <h3>Fund Manager Commentary</h3>
        </Col>
      </Row>
      <Row className="bg-light p-3 my-5">
        <Col>
            <CommentarySection content={parseCommentary(fundDetails.explicacion_informe)} previewLength={5} />
        </Col>
      </Row>
      <Row className="my-2">
      <hr />
        <Col>
          <h3>Derivatives</h3>
        </Col>
      </Row>
      <Row className="my-2">
        <Col>
          <DervidadosSection fundDetails={fundDetails}/>
        </Col>
      </Row>
      <Row className="my-2">
      <hr />
        <Col>
          <h3>Other operations</h3>
        </Col>
      </Row>
      <Row className="bg-light p-3 my-2">
        <Col>
          <p>
            {fundDetails.operaciones_vinculadas}
          </p>
        </Col>
      </Row>
      </>
      }
      </>
    )}
    </Container>
  );
};

export default FundDetails;
