import  LineGraph  from '../utils/Charts/LineGraph';
import BarGraph from '../utils/Charts/BarChart';
import StackedChart from '../utils/Charts/StackedChart';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { format_data } from '../utils/DataHelpers';
import { processKeys } from '../utils/Constants';

const FundGraphs = ({ data, isUSAFund}) => {
    //TODO: Mucha distancia entre gráficos si se redimensiona la ventana. Los gráficos no se hacen grandes cuando la ventana se redimensiona después de haberlos hecho más pequeños?
    //TODO: Mostrar año para porcentajes acumulados si el año no se ha acabado? 
    //TODO: Dar opción para hacer los gráficos grandes?
const processedData = format_data(data, processKeys);

//TODO: REVISAR TODOS ANTERIORES
return (
    <>
      {processedData.TER==undefined || processedData.comision_gestion_patrimonio==undefined || processedData.comision_depositario==undefined ? null :
      <>
      <Row className="my-3">
          <Col>
            <h4 >Expenses</h4>
          </Col>
        </Row>
        <Row className="my-3">
          <Col md={6} className="chart-height">
            <LineGraph series_data={[{'data':processedData.TER, 'name':'ter'}]} yAxisSymbol='%' isUSAFund={isUSAFund} />
          </Col>
          <Col md={6} className="chart-height">
            {isUSAFund &&  processedData.comision_gestion_patrimonio!=undefined && processedData.comision_depositario!=undefined ? 
            <StackedChart series_data={[{'data':processedData.comision_gestion_patrimonio, 'name':'comision_gestion_patrimonio'},{'data':processedData.comision_depositario, 'name':'comision_depositario'}]}  yAxisSymbol='%' isUSAFund={isUSAFund}/>
            : 
            <StackedChart series_data={[{'data':processedData.comision_gestion_patrimonio, 'name':'comision_gestion_patrimonio'},{'data':processedData.comision_depositario, 'name':'comision_depositario'},{'data':processedData.comision_gestion_resultados, 'name':'comision_gestion_resultados'}]}  yAxisSymbol='%' isUSAFund={isUSAFund}/>
          }
            </Col>
      </Row>
      </>
    }
      <Row className="my-3">
        <Col>
          <h4 > {isUSAFund ? "Return" :  "Return and volatility"}</h4>
        </Col>
      </Row>
      <Row className="my-3">
        <Col md={6} className="chart-height">
          <LineGraph series_data={[{'data':processedData.rentabilidad, 'name':'rentabilidad'}]} yAxisSymbol='%' isUSAFund={isUSAFund}/>
        </Col>
        {isUSAFund ? null :
        <Col md={6} className="chart-height">
          <LineGraph series_data={[{'data':processedData.volatilidad, 'name':'volatilidad'}]} yAxisSymbol='%' isUSAFund={isUSAFund} />
        </Col>
        }
      </Row>
      {isUSAFund ? 
        <>
          <Row className="my-3">
          <Col>
            <h4 >Realized and Unrealized gain</h4>
          </Col>
          </Row>
          <Row className="my-3">
          <Col md={6} className="chart-height">
          <BarGraph series_data={[{'data':processedData.realized_gain, 'name':'realized_gain'}]}  yAxisSymbol={processedData.moneda} isUSAFund={isUSAFund}/>
          </Col>
          <Col md={6} className="chart-height">
            <BarGraph series_data={[{'data':processedData.unrealized_gain, 'name':'unrealized_gain'}]} yAxisSymbol={processedData.moneda} isUSAFund={isUSAFund} />
          </Col>
          </Row>
        </>

      : // Valor Liquidativo sí que está disponible para el caso USA.
      <>
      <Row className="my-3">
        <Col>
          <h4 >Shareholders and NAV</h4>
        </Col>
      </Row>
      <Row className="my-3">
      {
      <Col md={6} className="chart-height">
      <BarGraph series_data={[{'data':processedData.num_participes, 'name':'num_participes'}]}  yAxisSymbol='' isUSAFund={isUSAFund}/>
      </Col>
      }
      <Col md={6} className="chart-height">
        <LineGraph series_data={[{'data':processedData.valor_liquidativo, 'name':'valor_liquidativo'}]} yAxisSymbol={processedData.moneda}  isUSAFund={isUSAFund}/>
      </Col>
      </Row>
      </>
      }
      <Row className="my-3">
        <Col>
          <h4 >Wealth and invested percentage</h4>
        </Col>
      </Row>
      <Row className="my-3">
      <Col md={6} className="chart-height">
        <BarGraph series_data={[{'data':processedData.patrimonio, 'name':'patrimonio'}]} yAxisSymbol={processedData.moneda} isUSAFund={isUSAFund}/>
      </Col>
      <Col md={6} className="chart-height">
        <LineGraph series_data={[{'data':processedData.porcentaje_invertido, 'name':'porcentaje_invertido'}]} yAxisSymbol='%' isUSAFund={isUSAFund}/>
      </Col>
      </Row>
      {isUSAFund ? null :
      <>
      <Row className="my-3">
        <Col>
          <h4 >Asset Variations</h4>
        </Col>
      </Row>
      
      <Row className="my-3">
      {
      <Col md={6} className="chart-height">
      <BarGraph series_data={[
        {'data':processedData.variacion_patrimonio_renta_variable, 'name':'renta_variable'},
        {'data':processedData.variacion_patrimonio_renta_fija, 'name':'renta_fija'},
        {'data':processedData.variacion_patrimonio_derivados, 'name':'derivados'},
        {'data':processedData.variacion_patrimonio_dividendos, 'name':'dividendos'},
        {'data':processedData.variacion_patrimonio_iic, 'name':'iic'}
      ]}
      yAxisSymbol='%'
      isUSAFund={isUSAFund}
      />
      </Col>
      }
      <Col md={6} className="chart-height">
      <BarGraph series_data={[
        {'data':processedData.variacion_patrimonio_suscripciones, 'name':'suscripciones'},
        {'data':processedData.variacion_patrimonio_beneficio, 'name':'beneficio'}
      ]}
      yAxisSymbol='%'
      isUSAFund={isUSAFund}
      />
      </Col>
    </Row>
    </>
    }
    </> 
  );
};

export default FundGraphs;
