
import React from 'react';

const Footer = () => {
    //<footer className="bg-dark text-white text-center py-3 mt-auto">
    return (
      <footer className="mt-5 py-3 bg-primary text-white text-center py-3 mt-auto">
        &copy; {new Date().getFullYear()} InFondos. All rights reserved.
      </footer>
    );
  };

  export default Footer;
