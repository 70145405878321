export const BASE_URL = 'https://apiv2.infondos.com/'; //'https://l0k124w8g5.execute-api.eu-south-2.amazonaws.com/DEV/'//

export const FUND_PERIODS_URL = (fund_key,fund_key_name) => `${BASE_URL}fondo_periodos?${fund_key_name}=${fund_key}`;
export const FUND_DETAILS_URL = (fund_key,fund_key_name, periodo) => `${BASE_URL}fondo_detalle?${fund_key_name}=${fund_key}&periodo_id=${periodo}`;
export const FUND_POSITIONS_URL = (fundId, periodo) => `${BASE_URL}fondo_posiciones?fondo_base_id=${fundId}&periodo_id=${periodo}`;

export const POSITION_PERIODS_URL = isinPosicion => `${BASE_URL}posicion_periodos?isin_posicion=${isinPosicion}`;
export const POSITION_FUNDS_URL = (isinPosicion, selectedQuarter) => `${BASE_URL}posicion_fondos?isin_posicion=${isinPosicion}&periodo_id=${selectedQuarter}`;
export const POSITION_DETAILS_URL = (isinPosicion, fondo_base_id, periodo) => `${BASE_URL}posicion_detalle?isin_posicion=${isinPosicion}&fondo_base_id=${fondo_base_id}&periodo_id=${periodo}`;
export const POSITION_TV = isinPosicion => `${BASE_URL}posicion_ticker?isin_posicion=${isinPosicion}`;

export const SEARCH_URL = name => `${BASE_URL}search?value=${name}`;
export const LETTER_URL = (country, letter) => `${BASE_URL}letter?country=${country}&letter=${letter}`;

export const USA_FLAG = `https://flagcdn.com/us.svg`
export const SPAIN_FLAG = `https://flagcdn.com/es.svg`


export const SUMMARY_LATEST_URL = `${BASE_URL}new_summary?is_latest=True`;
export const SUMMARY_URL = selectedPeriodo => `${BASE_URL}new_summary?periodo_id=${selectedPeriodo}`;
export const SUMMARY_FONDOS_URL = `https://cnmv-public-files-es.s3.eu-south-2.amazonaws.com/summary_fondos.json.gz`;
export const SUMMARY_POSICIONES_URL = `https://cnmv-public-files-es.s3.eu-south-2.amazonaws.com/summary_posiciones.json.gz`;

export const USER_FEEDBACK_URL = `${BASE_URL}user_feedback`;

//TODO: PARAMETRIZE NAMES (SPANISH-ENGLISH)
export const porcentajeKeys = ['porcentaje_invertido', 'rentabilidad', 'rentabilidad_liquidez', 'TER', 'rotacion_cartera', 'volatilidad', 'comision_gestion_patrimonio', 'comision_depositario', 'comision_gestion_resultados', 'performance_since_inception']
export const nonEurKeys = ['num_participes', 'num_participaciones', 'sustainability_rating', 'morningstar_rating'];
export const processKeys = ['total_portfolio', 'liquidez_portfolio', 'porcentaje_invertido', 'patrimonio','num_participes', 'rentabilidad','volatilidad','inversion_minima','comision_gestion_patrimonio','comision_depositario','comision_gestion_resultados', 'TER','valor_liquidativo', 'variacion_patrimonio_renta_fija', 'variacion_patrimonio_beneficio', 'variacion_patrimonio_dividendos', 'variacion_patrimonio_renta_variable','patrimonio', 'num_participes','beneficio_bruto_participacion', 'variacion_patrimonio_suscripciones', 'rotacion_cartera', 'variacion_patrimonio_iic','variacion_patrimonio_depositos', 'variacion_patrimonio_derivados','num_participaciones','rentabilidad_liquidez', 'realized_gain', 'unrealized_gain']
export const table_2_keys = ['rentabilidad', 'volatilidad', 'rotacion_cartera', 'TER', 'comision_gestion_patrimonio', 'comision_depositario', 'comision_gestion_resultados']
export const table_3_keys = ['patrimonio', 'num_participes', 'num_participaciones', 'total_portfolio', 'liquidez_portfolio', 'porcentaje_invertido']
export const table_usa_keys = ['rentabilidad', 'patrimonio', 'total_portfolio', 'liquidez_portfolio', 'porcentaje_invertido', 'TER', 'comision_gestion_patrimonio', 'comision_depositario']
//HACER EXPLÍCITO LAS KEYS QUE SÍ QUIERO TENER. AÑADIR INFO DE USA!
export const table_info_keys = ['tipo_fondo', 'isin_fondo', 'moneda', 'distribuye_dividendos', 'vocacion_inversora', 'tipo_producto', 'nombre_entidad', 'fecha_registro', 'morningstar_rating', 'sustainability_rating', 'benchmark', 'symbol', 'website' ]
export const letters = ['A','B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M','Ñ','O','P','Q','R','S','T','U','V','W','X','Y','Z', '#'];

export const colors_by_name = {
    ter: { name:'TER', borderColor: '#003366', backgroundColor: 'rgba(0, 51, 102, 0.5)' }, // Dark blue
    rentabilidad: { name:'Return', borderColor: '#4CAF50', backgroundColor: 'rgba(76, 175, 80, 0.5)' }, // Green
    rentabilidad_liquidez: { name: 'Liquidity Return', borderColor: '#00897B', backgroundColor: 'rgba(0, 137, 123, 0.5)' }, // Teal
    volatilidad: { name:'Volatility', borderColor: '#E53935', backgroundColor: 'rgba(229, 57, 53, 0.5)' }, // Red
    patrimonio: { name:'Wealth', borderColor: '#2E7D32', backgroundColor: 'rgba(46, 125, 50, 0.5)' }, // Deep green
    valor_liquidativo: { name:'NAV', borderColor: '#1565C0', backgroundColor: 'rgba(21, 101, 192, 0.5)' }, // Blue
    renta_variable: { name: 'Equity', borderColor: '#9C27B0', backgroundColor: 'rgba(156, 39, 176, 0.5)' }, // Purple
    renta_fija: { name: 'Fixed Income', borderColor: '#2196F3', backgroundColor: 'rgba(33, 150, 243, 0.5)' }, // Blue
    derivados: { name: 'Derivatives', borderColor: '#D32F2F', backgroundColor: 'rgba(211, 47, 47, 0.5)' }, // Red
    dividendos: { name: 'Dividends', borderColor: '#43A047', backgroundColor: 'rgba(67, 160, 71, 0.5)' }, // Green
    suscripciones: { name: 'Subscriptions', borderColor: '#1E88E5', backgroundColor: 'rgba(30, 136, 229, 0.5)' }, // Blue
    beneficio: { name: 'Profit', borderColor: '#8BC34A', backgroundColor: 'rgba(139, 195, 74, 0.5)' }, // Light green
    comision_gestion_patrimonio: { name: 'Management Fee', borderColor: '#FF9800', backgroundColor: 'rgba(255, 152, 0, 0.5)' }, // Orange
    comision_depositario: { name: 'Custodian Fee', borderColor: '#795548', backgroundColor: 'rgba(121, 85, 72, 0.5)' }, // Brown
    comision_gestion_resultados: { name: 'Results Fee', borderColor: '#607D8B', backgroundColor: 'rgba(96, 125, 139, 0.5)' }, // Blue Grey
    iic: { name: 'Other funds', borderColor: '#673AB7', backgroundColor: 'rgba(103, 58, 183, 0.5)' }, // Deep purple
    num_participes: { name: 'Nº of Shareholders', borderColor: '#FFC107', backgroundColor: 'rgba(255, 193, 7, 0.5)' }, // Yellow
    realized_gain: { name: 'Realized Gain', borderColor: '#FFC107', backgroundColor: 'rgba(255, 193, 7, 0.5)' }, // Yellow
    unrealized_gain: { name:'Unrealized Gain', borderColor: '#1565C0', backgroundColor: 'rgba(21, 101, 192, 0.5)' }, // Blue
    porcentaje_invertido: { name: 'Invested %', borderColor: '#95A5A6', backgroundColor: 'rgba(149, 165, 166, 0.5)' }, // Gray

};

export const flatly_color_palette = [ //Taken from flaty/bootstrap.css
    '#2c3e50', // primary
    //'#95a5a6', // secondary
    '#2c3e50', // bs-blue
    '#18bc9c', // bs-green
    '#3498db', // bs-cyan
    '#f39c12', // bs-yellow
    '#e74c3c', // bs-red
    '#95a5a6', // bs-gray
    '#6f42c1', // bs-purple
    //'#f8f9fa', // bs-white
    '#6610f2', // bs-indigo
    //'#212529', // bs-black
    '#e83e8c', // bs-pink
    '#fd7e14', // bs-orange
    '#20c997', // bs-teal
    '#607D8B', // bs-blue-grey

]
