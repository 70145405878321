import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { CAGR_calculation, format_data, formatLabel, formatValue} from '../utils/DataHelpers';
import TableComponent from './TableComponent';
import TableSingleRow from './TableSingleRow';
import FundGraphs from './FundGraphs';
import { table_2_keys,table_info_keys, table_3_keys, processKeys, table_usa_keys } from '../utils/Constants';
import RatingStars from './RatingStars';

//http://localhost:3002/position/USU8810LAA18
const FundInformation = ({ data, isUSAFund}) => {

  if (data.fecha_registro && data.performance_since_inception)
    {
      const cagr = CAGR_calculation(data.fecha_registro, data.performance_since_inception)
    }


  const filter_fund_information = (data) => {
    return Object.entries(data).filter(([key]) => table_info_keys.includes(key));
  };

  const filteredFundInformation = filter_fund_information(data);


  const fundInformation = filteredFundInformation.map(([key, value]) => ({
    label: formatLabel(key),
    value: formatValue(value, key),
  }));
  

  const characteristicsPerColumn = Math.ceil(fundInformation.length / 3);

  const columns = Array.from({ length: 3 }, (_, index) => (
    <Col md={4} key={index}>
      {fundInformation
        .slice(index * characteristicsPerColumn, (index + 1) * characteristicsPerColumn)
        .map((item, i) => {
          if (item.label === "Morningstar Rating" || item.label === "Sustainability Rating") {
            return (
              <div key={i}>
                <strong>{item.label === "Morningstar Rating" ? "Morningstar Rating" : "Sustainability Rating"}:</strong>
                <RatingStars rating={parseInt(item.value)} />
              </div>
            );
          } else {
            if (item.value==="Symbol")
              return (null)
            return (
              <div key={i}>
                <strong>{item.label}</strong>:
                {" " + item.value}
              </div>
            );
          }
        })}
    </Col>
  ));


  const keys = (data.periodo_id.includes("S2"))?[ data.periodo_id, data.año ] : [data.periodo_id]; // This could be replaced with a dynamic value
  const processedData = format_data(data, processKeys)

  const [show_graphs, setShowGraphs] = useState(false);


//"bg-light p-3 my-3"
  return (
    <Container fluid>
      <Row className="bg-light p-3 my-3">{columns}</Row>
      {
        isUSAFund ?
        <>
        <Row className="my-3">
        <Col>
          {<TableSingleRow table_data={processedData} table_keys={table_usa_keys} data={data} />}
        </Col>
      </Row>
        </>
       : 
        <> 
      <Row className="my-3">
        <Col>
          {<TableComponent table_data={processedData} table_keys={table_2_keys} keys={keys} />}
        </Col>
      </Row>
      <Row className="my-3">
        <Col>
          {<TableSingleRow table_data={processedData} table_keys={table_3_keys} data={data} />}
        </Col>
      </Row>
      </>
      }
      <Row className="my-5">
      <div className="d-flex align-items-center">
          <h3 className='me-5'>Evolution</h3>
          <button className="btn btn-primary" onClick={() => setShowGraphs(!show_graphs)}>
            {show_graphs ? 'Hide Graphs' : 'Show Graphs'}
          </button>
      </div>
      </Row>
      { show_graphs ?  
      <FundGraphs data={data} isUSAFund={isUSAFund} />
      : null 
      /*
      <Row className="my-5">
        <Col>
        <div className="text-center">
            <p><strong>Próximamente...</strong></p>
              <div className="loader-center"></div>
          </div>
        </Col>
      </Row>*/ }
    </Container>
  );
};

export default FundInformation;
