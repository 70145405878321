import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PieChart from '../utils/Charts/PieChart';
import { processCountryPositions, processSectorPositions, processTypePositions } from '../utils/Charts/ChartHelpers';




const FundPositionsCharts = ({ positionsData, isUSAFund }) => {

  return (
    <>
      <Row className="my-3">
        <Col>
          <h5 >By countries</h5>
        </Col>
      </Row>
      <Row className="my-3">
        <Col md={12} className="d-flex justify-content-center align-items-center pie-chart-height">
          <PieChart processedData={processCountryPositions(positionsData)} />
        </Col>
      </Row>
      {isUSAFund ? null :
      <>
      <Row className="my-3">
        <Col>
          <h5 >By sectors</h5>
        </Col>
      </Row>
      <Row className="my-3">
        <Col md={12} className="d-flex justify-content-center align-items-center pie-chart-height">
          <PieChart processedData={processSectorPositions(positionsData)} />
        </Col>
      </Row>
      </>
      }
      <Row className="my-3">
        <Col>
          <h5 >By Type of Holding</h5>
        </Col>
      </Row>
      <Row className="my-3">
        <Col md={12} className="d-flex justify-content-center align-items-center pie-chart-height">
          <PieChart processedData={processTypePositions(positionsData)} />
        </Col>
      </Row>
    </>
  );
};

export default FundPositionsCharts;
