import React, { useState, useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';
import { Tabs, Tab } from 'react-bootstrap';
import { categorizeChartData, generateChartData } from './ChartHelpers';



ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function StackedChart({ series_data, yAxisSymbol, isUSAFund }) {
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setInitialized(true);
        }, 500);
        return () => clearTimeout(timer);
    }, []);


    const options = {
        responsive: true,
        maintainAspectRatio: true,

        scales: {
            y: {
                beginAtZero: true,
                stacked: true,
                ticks: {
                    callback: function(value) {
                        return `${value} ${yAxisSymbol}`; // Appends the symbol to the tick label
                    }
                }
            },
            x: {
                stacked: true,
            },
        },
        plugins: {
            datalabels: {
                display: false
              },
            tooltip: {
                callbacks: {
                    label: function(context) {
                        let label = context.dataset.label || '';
    
                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += `${context.parsed.y} ${yAxisSymbol}`;
                        }
                        return label;
                    }
                }
            }
        }
    };

    const yearly_data = categorizeChartData('yearly', series_data);
    const quarterly_data = categorizeChartData('quarterly', series_data);
    const semester_data = categorizeChartData('semester', series_data);
    const monthly_data = categorizeChartData('monthly', series_data);

    return (
        <Tabs defaultActiveKey={isUSAFund ? "monthly" : "semester"} id="data-tabs">
            <Tab eventKey="yearly" title="Yearly">
                {initialized && <Bar options={options} data={generateChartData(yearly_data)} />}
            </Tab>
            {isUSAFund && (
                <Tab eventKey="monthly" title="Monthly">
                    {initialized && <Bar options={options} data={generateChartData(monthly_data)} />}
                </Tab>
            )}
            {!isUSAFund && quarterly_data.data && (
                <Tab eventKey="quarterly" title="Quarterly">
                    {initialized && <Bar options={options} data={generateChartData(quarterly_data)} />}
                </Tab>
            )}
            {!isUSAFund && (
                <Tab eventKey="semester" title="Semester">
                    {initialized && <Bar options={options} data={generateChartData(semester_data)} />}
                </Tab>
            )}
        </Tabs>
    );
};

export default StackedChart;
