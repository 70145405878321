import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Dropdown, DropdownButton, Table, ListGroup } from 'react-bootstrap';
import { useParams, useLocation, useNavigate } from 'react-router-dom'; 
import FundsTable from './stocks/FundsTable';
import StockSection from './stocks/StockSection';
import SidePanel from './SidePanel';
import SpinnerLoading from './utils/SpinnerLoading';
import CustomDropdown from './utils/CustomDropdown';
import { POSITION_TV} from './utils/Constants';
import { useFetch } from './hooks/fetchURL';
import { useFetchStock } from './hooks/fetchStock';
import { USA_FLAG, SPAIN_FLAG } from './utils/Constants';
import { Helmet } from 'react-helmet';

//TODO: Incluir VER-MÁS al cargar los fondos!


const StockDetails = () => {
    // Get the fund ID from the URL
  const { isinPosicion } = useParams();
  
  const [ticker, setTicker] = useState('NA');

  const {
    quarters,
    positionFundsData,
    fondoBaseID,
    selectedQuarter,
    setSelectedQuarter,
    USAquarters,
    USApositionFundsData, 
    selectedUSAQuarter, 
    setSelectedUSAQuarter, 
  } = useFetchStock(isinPosicion);


 
  const { data: position_tv_data,  error: positionTVError } = useFetch(POSITION_TV(isinPosicion));


  useEffect(() => {  // If 'position_tv_data' changes, we have the ticker.
    if (position_tv_data) {
      setTicker(position_tv_data.ticker)
    }
  }, [position_tv_data]);


  useEffect(() => {  // If we have the ticker, we can change the title of the page.
    if (ticker) {
      document.title = `${ticker} - InFondos`;
    }
    else {
      document.title = `${isinPosicion} - InFondos`;
    }
  }, [ticker]);

  function FundsSection({
    positionFundsData,
    quarters,
    selectedQuarter,
    setSelectedQuarter,
    title
  }) {
    // Render loading spinner if data is not ready
    var tableData = []
    if (quarters===null) {
      return <SpinnerLoading />;
    }
    else if (quarters!==null && quarters!==undefined && quarters.length===0)
    {
      tableData = []
    }
    else if (positionFundsData!==null && positionFundsData!==undefined)
    {
      tableData = positionFundsData;
    }

    //REVISAR QUÉ DATOS OBTENGO CUANDO CARGO SÓLO FONDOS SPAIN
    // Determine the data to be passed to the FundsTable based on the condition
    //const tableData = (quarters && quarters.length===0 && !positionFundsData) ? [] : positionFundsData;
  
    return (
      <>
        <Row className="my-3">
          <hr />
          <Col md={5} xs={6}>
            <h3>{title}</h3>
          </Col>
          <Col md={2} xs={6}>
            <CustomDropdown
              items={quarters}
              selectedItem={selectedQuarter}
              setSelectedItem={setSelectedQuarter}
              title="No Funds"
            />
          </Col>
        </Row>
        <Row className="my-3">
          <Col>
            <FundsTable data={tableData} loading={false} />
          </Col>
        </Row>
      </>
    );
  }
  

  return (
    <Container>
      <Row className="my-3">
        <SidePanel />
      </Row>
    {ticker!==null && ticker !== 'NA' ? (
      <StockSection tv_ticker={ticker} />
    ) : ticker === 'NA'  ? (
      <SpinnerLoading/>
    ) : (
      <StockSection isinPosicion={isinPosicion} fondo_base_id={fondoBaseID} periodo={selectedQuarter ? selectedQuarter!==undefined : selectedUSAQuarter} />
    )}
      <Helmet>
        <title>{document.title}</title>
        <meta name="description" content={"Funds and ETFs that contains the holding "+ isinPosicion} />
        <meta name="keywords" content={["cartera", "portfolio", "holdings", isinPosicion, "funds with the holding"]} />
      </Helmet>
    <FundsSection
          positionFundsData={positionFundsData}
          quarters={quarters}
          selectedQuarter={selectedQuarter}
          setSelectedQuarter={setSelectedQuarter}
          title={<><img src={SPAIN_FLAG} alt="ES flag" style={{ width: '40px', height: '30px'}} /> Funds with the Holding </> }

        />
{
      <FundsSection
        positionFundsData={USApositionFundsData}
        quarters={USAquarters}
        selectedQuarter={selectedUSAQuarter}
        setSelectedQuarter={setSelectedUSAQuarter}
        title={<><img src={USA_FLAG} alt="US flag" style={{ width: '40px', height: '30px'}} /> Funds with the Holding </> }
    /> }
    </Container>
  );
};

export default StockDetails;