import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Card } from 'react-bootstrap';
import { useSetLanguage } from '../hooks/setLanguage';

export  const LandingPageCarousel = () => {
  const language = useSetLanguage()


    // Define the custom arrow components
function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", backgroundColor: "#2C3E50",  borderRadius: "50%", width: "30px", height: "30px", padding: "5.5px"}}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", backgroundColor: "#2C3E50", borderRadius: "50%", width: "30px", height: "30px", padding: "5.5px"}}
        onClick={onClick}
      />
    );
  }

  const cardContents = {
    en: [
      { title: 'The Fund-ETF oriented Alternative to Dataroma', text: 'We offer all the details of portfolios from US/Spanish fund/ETF managers. Discover which funds have the same positions in their portfolios and much more.' },
      { title: 'Invest Like the Best', text: 'Use collective intelligence to discover new investment opportunities. Take the ideas from the best managers and make them your own.' },
      { title: 'SEC & CNMV but Simplified', text: 'Access the most recent information from the SEC (US) and CNMV (Spain) in a simplified and comparable manner. Discover new positions in Funds/ETFs/SICAVs along with manager comments and their explanations.' },
      { title: 'Compare Historical Data', text: 'What was the composition of a fund\'s portfolio at a specific time? What positions did a manager increase or decrease at a particular moment? Discover the answers to these questions and much more.' },
      { title: 'Quarterly/Semiannualy Fund Details', text: 'We keep you up to date with the updates of the fund portfolios, allowing you to adapt your investment decisions based on the latest information.' },
      { title: 'Delve into the Fund Positions', text: 'Explore the positions of each fund in detail. Every position is presented with complete information about the stock, its fundamentals, and its quotation. Quality, simplified, and comparable information at your fingertips.' },
      { title: 'Much More', text: 'Discover new features that we will be adding to the platform. We are working on it! Follow us on Twitter to stay updated (@infondos) and leave your suggestions in the dropdown on the right.' }
    ],
    es: [
      { title: 'La Alternativa a Dataroma Orientada a Fondos-ETF', text: 'Ofrecemos todos los detalles de las carteras de gestores de fondos y ETFs de EE.UU./España. Descubre qué fondos comparten posiciones en sus carteras y mucho más.' },
      { title: 'Invierte como los mejores', text: 'Utiliza la inteligencia colectiva para descubrir nuevas oportunidades de inversión. Adopta las ideas de los mejores gestores y hazlas tuyas.' },
      { title: 'SEC y CNMV Simplificadas', text: 'Accede a la información más reciente de la SEC (EE.UU.) y la CNMV (España) de manera simplificada y comparable. Descubre nuevas posiciones en Fondos/ETFs/SICAVs junto a los comentarios de los gestores y sus explicaciones.' },
      { title: 'Compara Datos Históricos', text: '¿Cuál era la composición de la cartera de un fondo en un momento específico? ¿Qué posiciones aumentó o redujo un gestor en un momento dado? Descubre las respuestas a estas preguntas y mucho más.' },
      { title: 'Detalles de Fondos Cuatrimestrales/Semestrales', text: 'Te mantenemos informado sobre las actualizaciones de las carteras de los fondos, permitiéndote adaptar tus decisiones de inversión basadas en la información más reciente.' },
      { title: 'Profundiza en las Posiciones de los Fondos', text: 'Explora las posiciones de cada fondo en detalle. Cada posición se presenta con información completa sobre la acción, sus fundamentales y su cotización. Información de calidad, simplificada y comparable, al alcance de tu mano.' },
      { title: 'Mucho más', text: 'Descubre las nuevas funcionalidades que estamos añadiendo a la plataforma. ¡Estamos trabajando en ello! Síguenos en Twitter para mantenerte al día (@infondos) y deja tus sugerencias en el desplegable de la derecha.' }
    ],
  };
  
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
            }
          },
          {
            breakpoint: 780,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
    

    return (
    <Slider {...settings}>
      {cardContents[language].map((cardContent, index) => (
            <div key={index}>
                <Card style={{ width: '20rem', marginLeft: '20px', marginRight: '20px' }}>
                    <Card.Body>
                        <Card.Title>{cardContent.title}</Card.Title>
                        <Card.Text>
                            {cardContent.text}
                        </Card.Text>
                    </Card.Body>
                </Card>
            </div>
        ))}
        </Slider>
    );
}