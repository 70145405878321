import React from 'react';
import { ProgressBar, Card, Tooltip, OverlayTrigger, Row, Col } from 'react-bootstrap';

const InvestmentPerformanceCard = ({ inceptionDate, cagr, performanceSinceInception }) => {
  const performanceTooltip = (props) => (
    <Tooltip id="performance-tooltip" {...props}>
      Performance Since Inception
    </Tooltip>
  );

  const cagrTooltip = (props) => (
    <Tooltip id="cagr-tooltip" {...props}>
      Compound Annual Growth Rate (CAGR)
    </Tooltip>
  );

  // Determine bar variant based on performance
  const performanceVariant = performanceSinceInception < 0 ? 'danger' : 'primary';
  const performanceValue = Math.abs(performanceSinceInception);

  return (
    <Card style={{ width: '100%', marginBottom: '1rem' }}>
      <Card.Body>
        <Row className="align-items-center">
          <Col xs="3">Performance (since {inceptionDate})</Col>
          <Col xs="9">
            <OverlayTrigger placement="top" overlay={performanceTooltip}>
              <ProgressBar 
                now={performanceValue} 
                label={`${performanceSinceInception}%`} 
                variant={performanceVariant}
                style={{ height: '20px' }} 
              />
            </OverlayTrigger>
          </Col>
        </Row>

        <Row className="align-items-center" style={{ marginTop: '0.5rem' }}>
          <Col xs="3">CAGR</Col>
          <Col xs="9">
            <OverlayTrigger placement="top" overlay={cagrTooltip}>
              <ProgressBar 
                now={cagr} 
                variant="success" 
                label={`${cagr}%`} 
                style={{ height: '20px' }} 
              />
            </OverlayTrigger>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default InvestmentPerformanceCard;
