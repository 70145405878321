import React, { useState } from 'react';
import { Table, Tab, Nav, Button } from 'react-bootstrap';
import SpinnerLoading from '../utils/SpinnerLoading';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { formatPercentage, processPercentageRow, processTotalRow, sortDataByPercentage, style, apply_percentage_filter} from '../utils/DataHelpers';
import { TableButton } from '../landing/TableFunds';

//TODO: Update link to fund_by_name/item.fondo_nombre_id
const FundsTable = ({ data, loading }) => {
  const [activeTab, setActiveTab] = useState('all');
  const [numRowsDisplayed, setNumRowsDisplayed] = useState(5);


  const filteredPositions = sortDataByPercentage(data);
  const sells = filteredPositions.filter((item) => Math.abs(item.accion_diff_percentage)>=2 & item.accion_diff_percentage<0 )
  const buys = filteredPositions.filter( (item) => Math.abs(item.accion_diff_percentage)>=2 & item.accion_diff_percentage>0 )
  const all = filteredPositions.filter((item) => item.accion_diff_percentage!=-100 | (item.estado !== null && item.estado !== undefined && item.estado!='VENTA'));


  const getRows = (funds) => {
    // Conditional rendering based on the presence of funds.
    return funds
      ? funds.slice(0, numRowsDisplayed).map((fund, idx) => (
          <tr key={idx}>
            <td><a href={`/fund_by_name/${fund.fondo_nombre_id}`}>{fund.fondo_nombre}</a></td>
            <td>{fund.since_periodo}</td>
            <td>{fund.tipo_fondo}</td>
            <td style={style(apply_percentage_filter(fund.accion_diff_percentage))}>
              {formatPercentage(apply_percentage_filter(fund.accion_diff_percentage))}
            </td>
            <td>{processPercentageRow(fund)}</td>
            <td>{processTotalRow(fund)}</td>
          </tr>
        ))
      : <tr></tr>; // Returns an empty row if there are no funds.
  };


  const getTable = (funds, eventkey) => {
    return (
      <Tab.Pane eventKey={eventkey}>
      <div style={{overflowX: 'auto', width: '100%'}}>
        <Table striped bordered hover size="sm">
          {/* Table structure */}
          <thead>
          <tr>
            <th>Fund Name</th>
            <th>Since<AiOutlineInfoCircle className="info-icon" title="Minimun period since which the fund is holding the position (Min available period for spanish funds: 2021_S1. Min available period for US funds: 2023_Q1)" /></th>
            <th>Type</th>
            <th>Activity %<AiOutlineInfoCircle className="info-icon" title="Percentage difference in the number of shares for the fund holding" /></th>
            <th>Fund Holding %</th>
            <th>Fund Holding Value</th>
          </tr>
        </thead>
          <tbody>{getRows(funds)}</tbody>

        </Table>
        </div>
        <TableButton  funds={funds} numRowsDisplayed={numRowsDisplayed} setNumRowsDisplayed={setNumRowsDisplayed} all_rows={true} />
      </Tab.Pane>
    )
  };

  


  if (loading) {
    return (
      <SpinnerLoading/>
    );
  }

  return (
    <Tab.Container activeKey={activeTab} onSelect={(key) => setActiveTab(key)}>
      <Nav variant="tabs" className="mb-3">
        <Nav.Item>
          <Nav.Link eventKey="all">All</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="sells">Sells</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="buys">Buys</Nav.Link>
        </Nav.Item>
      </Nav>
      <Tab.Content>
        {getTable(all, 'all')}
        {getTable(sells, 'sells')}
        {getTable(buys, 'buys')}
      </Tab.Content>
    </Tab.Container>
  );
}

export default FundsTable;
